import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  Spinner
} from "reactstrap";
//import moment from "moment";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { isMobile } from "react-device-detect";
import Select from 'react-select';
import { debounce } from "throttle-debounce";

import "views/index.css";
import { addCustomer, getCustomers, exportCustomers, assignCustomers }  from 'services/customerService';
import { searchUsers }  from 'services/userService';
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddCustomer from "./AddCustomer.jsx";

class Customers extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        activeNav: 1,
        loading: true,
      	page: 1,
      	rows: 10,
      	total: 0,
        states: [],
        search: '',
        territories: [],
        axes: [],
        zones: [],
        teams: [],
        state: 'All States',
        territory: 'All Territories',
        axis: 'All Axes',
        zone: 'All Zones',
        user: 'All Users',
        team: 'All Teams',
        customers: [],
        users: [],
        assignUser: null,
        assignTeam: '',
        products: [],
        assigntype: 'User'
      };
      this.onSearch = debounce(500, this.searchUsers);
  }

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
    this.getCustomers();
  }

  componentWillMount() {
  }

  saveCustomer = (e) => {
    e.preventDefault();
    const {name, phone, email, address} = this.state;
    addCustomer({name, phone, email, address})
      .then(res => {
          this.setState({message : 'Customer added successfully.'});
          this.props.history.push('/customers');
      },
    	error => {
    		console.log(error)
    	});
  }

  getCustomers = () => {
    const {state, territory, axis, zone, user, team, page, rows, search} = this.state;
    this.setState({loading: true});
    getCustomers({state, territory, axis, zone, user, team, page, rows, search})
      .then((res) => {
        this.setState({
          customers: res.customers.data,
          states: res.states,
          territories: res.territories,
          axes: res.axes,
          zones: res.zones,
          teams: res.teams,
          page: res.customers.current_page,
          total: res.customers.total,
          products: res.products.map(p=>({label:p.name, value:p.id})),
          loading: false
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  searchUsers = (text) => {
    const search = text.replace(/\s+/g,' ').trim().replace(/\s/g, '');
    if(search.length>0){//console.log(search)
      this.setState({searching: true});
      searchUsers({search})
        .then((res) => {
          this.setState({
            users: res.users.map(u=>({label:u.name, value:u.id})),
            searching: false
          })
        },
        error => {
          this.setState({searching: false});
        });
    } else {
      this.setState({users: []});
    }
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addCustomer: !this.state.addCustomer});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  toggleAssign = () => {
    this.setState({showAssign: !this.state.showAssign});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  showToast = msg => {
      toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onFilter = async (e, filter) => {
    if(filter==='state'){
      await this.setState({territory: 'All Territories', axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='territory'){
      await this.setState({axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='axis'){
      await this.setState({zone: 'All Zones'});
    }
    await this.setState({[filter]: e});
    await this.getCustomers();
  }

  getMap = (item) => {
      return {f: `HYPERLINK("http://www.google.com/maps/place/${item.latitude},${item.longitude}","[${item.longitude}, ${item.latitude}]")`};
  }

  export = () => {
    const {state, territory, axis, zone, team, user, search} = this.state;
    this.setState({ loading: true });
    exportCustomers({state, territory, axis, zone, team, user, search})
      .then(response => {
        let exportt = '';
        exportt = response.customers.map(c => (
          {name: c.name, phone: c.phone, address: c.address, products: c.the_products, user: c.user_name?c.user_name:'N/\A', state: c.state_id,
            territory: c.territory_id, axis: c.axis_id, zone: c.zone_id, status: c.status?'Active':'Inactive',
            created_at: c.created_at, gps_coordinates: c.longitude?this.getMap(c):'N/\A'}
        ));

        const theheader = ['name','phone','address','products','user','state','territory','axis','zone','status','created_at','gps_coordinates'];
        const wch = [25,15,25,20,20,20,40,40,20,10,20,22];
        const cols = wch.map(h => {return ({wch: h});});
        const thedata = exportt.map(item => {
          return theheader.map(item2 => {
            return item[item2]
          })
        });
        const allofit = [theheader].concat(thedata);

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        ws['!cols'] = cols;
        XLSX.utils.book_append_sheet(wb, ws, 'Customers');
        XLSX.writeFile(wb, 'customers.xlsx');
        this.setState({
          loading: false
        });
      },
      error => {
        this.setState({ loading: false });
      });
  }

  onAssign = () => {
    const {state, territory, axis, zone, user, team, page, rows, search, assignUser, assignTeam, total, assigntype, customers} = this.state;
    if(assigntype==='User'&&!assignUser){
      this.showToast('Please select a User.')
    } else if(assigntype==='Team'&&!assignTeam){
      this.showToast('Please select a Team.')
    } else {
      this.setState({assigning: true});
      assignCustomers({state, territory, axis, zone, user, team, page, rows, search, user_id:assignUser?assignUser.value:null, team_id:assignTeam})
        .then((res) => {
          this.setState({
            assigning: false,
            customers: res.customers?res.customers.data:customers
          })
          this.showToast(`Customer${total==1?'':'s'} assigned to ${assigntype==='User'?assignUser.label:assignTeam} successfully.`)
        },
        error => {
          this.setState({assigning: false});
        });
    }
  }

  onPage = async (page) => {
    await this.setState({page});
    await this.getCustomers();
  }

  render() {
    const {customers, showFilter, states, state, territories, territory, addCustomer, users, searching, products,
      axes, axis, zones, zone, total, page, rows, search, loading, showAssign, assignUser, user, assigning,
      team, teams, assigntype, assignTeam} = this.state;
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 200,
        width: 'fit-content'
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
      }
    }

    const googleMaps = (customer) => {
      var win = window.open(`http://www.google.com/maps/place/${customer.latitude},${customer.longitude}`, '_blank');
      win.focus();
    }

    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addCustomer&&
          <AddCustomer
            saved={this.getCustomers}
            toggle={this.toggleModal}
            addCustomer={addCustomer}
            states={states}
            products={products}
          />}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{overflowX: 'auto', flexWrap: 'nowrap', minHeight: 42, marginLeft: 0, justifyContent: 'space-between'}}>
                    <div style={{whiteSpace: 'nowrap', marginRight: 10}}>
                      <h3 className="mb-0">Customers <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div style={{display: 'flex'}}>
                      {!showFilter&&<Button
                        color="warning"
                        onClick={this.toggleFilter}
                        size="sm"
                        style={{marginRight: 10}}
                      >
                        Filter
                      </Button>}
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 70}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={async (e) => {if(e.key==='Enter'){await this.setState({page:1});await this.getCustomers();}}} />
                      <Button className="btn-icon btn-2" color="secondary" size="sm" style={{marginRight: 10}} onClick={async() => {await this.setState({page:1});await this.getCustomers();}}>
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        onClick={() => this.toggleModal()}
                        size="sm"
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}
                      >
                        Add{isMobile?'':' Customer'}
                      </Button>
                      {!showAssign&&<Button
                        color="warning"
                        onClick={this.toggleAssign}
                        size="sm"
                        style={{marginRight: 10}}
                      >
                        Assign
                      </Button>}
                      <Button
                        color="success"
                        onClick={this.export}
                        size="sm"
                        disabled={loading}
                        //style={{marginRight: 10}}
                      >
                        Export
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {showFilter&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflowX: 'auto'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Filter Customers: </span>
                    <Input value={state} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'state')}>
                      <option>All States</option>
                      {states.map((s)=><option value={s} key={s}>{s}</option>)}
                    </Input>
                    {state!=='All States'&&
                    <Input value={territory} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'territory')}>
                      <option>All Territories</option>
                      {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                    </Input>}
                    {territory!=='All Territories'&&
                    <Input value={axis} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'axis')}>
                      <option>All Axes</option>
                      {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                    </Input>}
                    {axis!=='All Axes'&&
                    <Input value={zone} type="select" bsSize="sm"  style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'zone')}>
                      <option>All Zones</option>
                      {zones.map((z)=><option value={z} key={z}>{z}</option>)}
                    </Input>}
                    <Input value={team} type="select" bsSize="sm"  style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'team')}>
                      <option>All Teams</option>
                      {teams.map((z)=><option value={z} key={z}>{z}</option>)}
                    </Input>

                  </div>
                  <Button
                    color="warning"
                    onClick={()=>{this.toggleFilter();this.onFilter('All States', 'state');}}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Hide Filters
                  </Button>
                </div>}
                {showAssign&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', opacity: assigning?0.5:1}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 5, fontSize: 14}}>
                      {total>0?`Asssign ${total} Customer${total==1?'':'s'} to`:'No Customer(s) to assign'}
                    </span>
                    {total>0&&<Input value={assigntype} type="select" bsSize="sm"  style={{marginRight: 5, width: 'fit-content'}} onChange={e=>this.setState({assigntype:e.target.value, assignTeam: '', assignUser: null})}>
                      <option value='User'>User</option>
                      <option value='Team'>Team</option>
                    </Input>}
                    {total>0&&<span style={{marginRight: 10, fontSize: 14}}>:</span>}
                    {assigntype==='Team'&&
                    <Input disabled={total<1} value={assignTeam} type="select" bsSize="sm"  style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.setState({assignTeam:e.target.value})}>
                      <option disabled value=''>Select Team</option>
                      {teams.map((z)=><option value={z} key={z}>{z}</option>)}
                    </Input>}
                    {assigntype==='User'&&
                    <Select
                      isSearchable
                      onInputChange={this.onSearch}
                      placeholder="Type to search..."
                      styles={customStyles}
                      value={assignUser}
                      onChange={e=>this.setState({assignUser:e})}
                      options={users}
                      isLoading={searching||assigning}
                      isDisabled={total<1}
                      noOptionsMessage={(e) => assignUser&&e.inputValue===''?"Start typing to search...":"No user found."}
                    />}
                    <Button
                      color="warning"
                      onClick={this.onAssign}
                      size="sm"
                      style={{marginLeft: 10}}
                      disabled={total<1||assigning}
                    >
                      Assign
                    </Button>
                    {assigning&&<Spinner type="grow" color="success" style={{width: '2rem', height: '2rem', zIndex: 10001}} />}

                  </div>
                  <Button
                    color="warning"
                    onClick={this.toggleAssign}
                    size="sm"
                    style={{marginRight: 10}}
                    disabled={assigning}
                  >
                    Cancel
                  </Button>
                </div>}
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Address</th>
                      <th scope="col">Product(s)</th>
                      <th scope="col">User</th>
                      <th scope="col">Team</th>
                      <th scope="col">Location</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customers.map((customer, key) => {
                      return (
                        <tr key={key} className="hover-list">
                          <th scope="row" onClick={() => this.props.history.push('/admin/customers/'+customer.id)}>
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {customer.name}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td onClick={() => this.props.history.push('/admin/customers/'+customer.id)}>{customer.phone}</td>
                          <td onClick={() => this.props.history.push('/admin/customers/'+customer.id)}>{customer.address}</td>
                          <td onClick={() => this.props.history.push('/admin/customers/'+customer.id)}>{customer.the_products}</td>
                          <td onClick={() => this.props.history.push('/admin/customers/'+customer.id)}>{customer.user_name}</td>
                          <td onClick={() => this.props.history.push('/admin/customers/'+customer.id)}>{customer.team_id||'-'}</td>
                          <td className="hover-icon" style={{cursor: customer.longitude===null?'default':'null'}}>
                            {customer.longitude?<i id={`map-${customer.id}`} className="fa fa-map-marker-alt" onClick={()=>googleMaps(customer)}/>:'N/A'}
                            {customer.longitude&&<UncontrolledTooltip placement="right" target={`map-${customer.id}`}>
                              View
                            </UncontrolledTooltip>}
                          </td>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&customers.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No customer found
                </div>}
                {customers.length>0&&<CardFooter className={`py-4 ${loading?'load-opacity':''}`}>
                  <CustomPagination page={page} total={total} rows={rows} onPage={this.onPage} />
                </CardFooter>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Customers;
