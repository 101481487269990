import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const zoneService = {
    addZone,
    getZones,
    updateZone,
    deleteZone
};

export function getZones(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}zones`, requestOptions).then(authService.handleResponse);
}

export function addZone(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addzone`, requestOptions).then(authService.handleResponse);
}

export function updateZone(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatezone/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteZone(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletezone/${id}`, requestOptions).then(authService.handleResponse);
}
