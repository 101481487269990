import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const visitService = {
    addVisit,
    getVisits,
    updateVisit,
    deleteVisit,
    exportVisits
};

export function getVisits(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}visits`, requestOptions).then(authService.handleResponse);
}

export function addVisit(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addvisit`, requestOptions).then(authService.handleResponse);
}

export function updateVisit(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatevisit/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteVisit(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletevisit/${id}`, requestOptions).then(authService.handleResponse);
}

export function exportVisits(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}exportvisits`, requestOptions).then(authService.handleResponse);
}