import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const customerService = {
    addCustomer,
    getCustomers,
    updateCustomer,
    deleteCustomer,
    getCustomer,
    exportCustomers
};

export function getCustomers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}customers`, requestOptions).then(authService.handleResponse);
}

export function getCustomer(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}customer/${id}`, requestOptions).then(authService.handleResponse);
}

export function addCustomer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addcustomer`, requestOptions).then(authService.handleResponse);
}

export function updateCustomer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatecustomer/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteCustomer(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletecustomer/${id}`, requestOptions).then(authService.handleResponse);
}

export function exportCustomers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}exportcustomers`, requestOptions).then(authService.handleResponse);
}

export function assignCustomers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}assigncustomers`, requestOptions).then(authService.handleResponse);
}
