import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';
import Select from 'react-select';

import { addCustomer }  from 'services/customerService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";

//const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg=/^([0]\d{10})$/;

class AddCustomer extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      activeNav: 1,
      loading: false,
      states: props.states,
      search: '',
      territories: [],
      axes: [],
      zones: [],
      products: props.products,
      state_id: '',
      territory_id: '',
      axis_id: '',
      zone_id: '',
      validation: {},
      name: '',
      address: '',
      phone: '',
      email: '',
      status: '',
      active: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {name, phone, products_id, validation} = this.state;
    await this.setState({
      validation: {
        ...validation,
        name: name!=='',
        phone: phoneReg.test(phone),
        products_id: products_id&&products_id.length>0,
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveCustomer();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if(field==='name'){
      return 'Name is required';
    } else if(field==='phone'){
      return 'Phone no is required';
    } else if(field==='products_id'){
      return 'Product(s) are required';
    }
  }

  saveCustomer = () => {
    this.setState({loading: true});
    const {name, phone, email, state_id, territory_id, zone_id, axis_id, active, products_id} = this.state;
    addCustomer({name, phone, email, state_id, territory_id, zone_id, axis_id, active, products: products_id.map(p=>p.value)})
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('Customer Created')
      },
    	error => {
    		console.log(error)
        if(error.phone){
          this.showToast('A customer with this Phone number already exixts')
        }
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    if(state==='state_id'){
      this.setState({territory_id: '', axis_id: '', zone_id: ''})
    }
    if(state==='territory_id'){
      this.setState({axis_id: '', zone_id: ''})
    }
    if(state==='axis_id'){
      this.setState({zone_id: ''})
    }
    this.setState({[state]: e})
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes,
            zones: res.zones
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  render() {
    const { addCustomer, toggle } = this.props
    const {states, territories, saving, axes, zones, loading, state_id, territory_id, zone_id,
      axis_id, active, validation, show, filtering, products, products_id} = this.state;
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: 'hsl(0,0%,95%)',
        boxShadow: '1px 1px 1px rgba(50, 50, 50, 0.1)'
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
      }
    }

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={addCustomer}
          toggle={() => !saving&&toggle}
          style={{maxWidth: 700}}
        >
          {loading&&<SpinDiv text={'Saving...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Customer
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{color: validation.name===false?'red':null}}
                  >
                    <Required />Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-name"
                    onChange={e=>this.onChange(e.target.value, 'name')}
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{color: validation.phone===false?'red':null}}
                  >
                    <Required />Phone No
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={e=>this.onChange(e.target.value, 'phone')}
                    placeholder="Phone No"
                    type="text"
                  />
                </FormGroup>
              </Col>
              </Row>
              <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                  >
                    Address
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-address"
                    onChange={e=>this.onChange(e.target.value, 'address')}
                    placeholder="Address"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-email"
                    style={{color: validation.email===false?'red':null}}
                  >
                    <Required />Product(s)
                  </label>
                  <Select
                    isMulti
                    placeholder="Select Product(s)"
                    styles={customStyles}
                    value={products_id}
                    onChange={e=>this.onChange(e, 'products_id')}
                    options={products}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    State
                  </label>
                  <Input value={state_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'state_id');
                      await this.filter();
                    }}>
                    <option disabled value=''>Select State</option>
                    {states.map((s)=><option value={s} key={s}>{s}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Territory
                  </label>
                  <Input value={territory_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'territory_id');
                      await this.filter();
                    }}>
                    <option disabled value=''>{filtering?"loading...":'Select Territory'}</option>
                    {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Axis
                  </label>
                  <Input value={axis_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'axis_id');
                      await this.filter();
                    }}>
                    <option disabled value=''>{filtering?"loading...":'Select Axis'}</option>
                    {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Zone
                  </label>
                  <Input value={zone_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} disabled={filtering} onChange={e=>this.onChange(e.target.value, 'zone_id')}>
                    <option disabled value=''>{filtering?"loading...":'Select Zone'}</option>
                    {zones.map((z)=><option value={z} key={z}>{z}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
              <Col lg="12">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={active}
                      id="active"
                      onChange={e=>this.onChange(e.target.checked, 'active')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="active">
                      Active
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="success" type="button" disabled={saving} size="sm"
              onClick={this.onSave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddCustomer;
