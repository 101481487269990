import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';

import { addUser }  from 'services/userService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";

const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//const phoneReg=/^([0]\d{10})$/;

class AddUser extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      activeNav: 1,
      loading: false,
      roles: props.roles,
      states: props.states,
      search: '',
      territories: props.territories,
      axes: props.axes,
      zones: props.zones,
      role_id: '',
      state_id: '',
      territory_id: '',
      axis_id: '',
      zone_id: '',
      superior_id: '',
      superiors: props.superiors,
      web: true, mobile: true, active: true, admin: false,
      calls: false, sms: false, contacts: false, location: true, change_password: true,
      validation: {},
      firstname: '',
      lastname: '',
      email: '',
      password: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {firstname, lastname, email, password, validation} = this.state;
    await this.setState({
      validation: {
        ...validation,
        firstname: firstname!=='',
        lastname: lastname!=='',
        email: emailReg.test(email),
        password: password.length>=6
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveUser();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if(field==='firstname'){
      return 'First name is required';
    } else if(field==='lastname'){
      return 'Last name is required';
    } else if(field==='email'){
      return 'Email is invalid';
    } else if(field==='password'){
      return 'Password must be at least 6 characters long';
    }
  }

  saveUser = () => {
    this.setState({loading: true});
    const {firstname, lastname, staffid, password, email, phone, altphone, change_password, role_id, state_id,
      territory_id, zone_id, axis_id, superior_id, web, mobile, active, admin,
      calls, sms, contacts, location} = this.state;
    addUser({firstname, lastname, staffid, password, email, phone, altphone, change_password, role_id, state_id,
      territory_id, zone_id, axis_id, superior_id, web, mobile, active, admin,
      calls, sms, contacts, location})
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('User Created')
      },
    	error => {
    		console.log(error)
        if(error.email){
          this.showToast('A user with this Email address already exixts')
        }
        if(error.phone){
          this.showToast('A user with this Phone number already exixts')
        }
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    if(state==='state_id'){
      this.setState({territory_id: '', axis_id: '', zone_id: ''})
    }
    if(state==='territory_id'){
      this.setState({axis_id: '', zone_id: ''})
    }
    if(state==='axis_id'){
      this.setState({zone_id: ''})
    }
    this.setState({[state]: e})
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes,
            zones: res.zones
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  render() {
    const { addUser, toggle } = this.props
    const {roles, states, territories, saving,
      axes, zones, loading, change_password, role_id, state_id, territory_id, zone_id,
      axis_id, superiors, superior_id, web, mobile, active, admin, filtering,
      calls, sms, contacts, location, validation, show} = this.state;
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={addUser}
          toggle={() => !saving&&toggle}
          style={{maxWidth: 800}}
        >
          {loading&&<SpinDiv text={'Saving...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add User
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                    style={{color: validation.firstname===false?'red':null}}
                  >
                    <Required />First name
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-first-name"
                    onChange={e=>this.onChange(e.target.value, 'firstname')}
                    placeholder="First name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                    style={{color: validation.lastname===false?'red':null}}
                  >
                    <Required />Last name
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-last-name"
                    onChange={e=>this.onChange(e.target.value, 'lastname')}
                    placeholder="Last name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-staffid"
                  >
                    Staff ID
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-staffid"
                    onChange={e=>this.onChange(e.target.value, 'staffid')}
                    placeholder="Staff ID"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-email"
                    style={{color: validation.email===false?'red':null}}
                  >
                    <Required />Email
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-email"
                    onChange={e=>this.onChange(e.target.value, 'email')}
                    placeholder="Email"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                  >
                    Phone
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={e=>this.onChange(e.target.value, 'phone')}
                    placeholder="Phone"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-alt-phone"
                  >
                    Alternative Phone
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-alt-phone"
                    onChange={e=>this.onChange(e.target.value, 'altphone')}
                    placeholder="Alternative Phone"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Role
                  </label>
                  <Input value={role_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} onChange={e=>this.onChange(e.target.value, 'role_id')}>
                    <option disabled value=''>Select Role</option>
                    {roles.map((r)=><option value={r} key={r}>{r}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    State
                  </label>
                  <Input value={state_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'state_id');
                      await this.filter();
                    }}>
                    <option disabled value=''>Select State</option>
                    {states.map((s)=><option value={s} key={s}>{s}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Territory
                  </label>
                  <Input value={territory_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'territory_id');
                      await this.filter();
                    }}>
                    <option disabled value=''>Select Territory</option>
                    {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Superior
                  </label>
                  <Input value={superior_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} disabled={filtering} onChange={e=>this.onChange(e.target.value, 'superior_id')}>
                    <option disabled value=''>Select Superior</option>
                    {superiors.map((s)=><option value={s} key={s}>{s}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Axis
                  </label>
                  <Input value={axis_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'axis_id');
                      await this.filter();
                    }}>
                    <option disabled value=''>Select Axis</option>
                    {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Zone
                  </label>
                  <Input value={zone_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} disabled={filtering} onChange={e=>this.onChange(e.target.value, 'zone_id')}>
                    <option disabled value=''>Select Zone</option>
                    {zones.map((z)=><option value={z} key={z}>{z}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={web}
                      id="web"
                      onChange={e=>this.onChange(e.target.checked, 'web')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="web">
                      Web App
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={mobile}
                      id="mobile"
                      onChange={e=>this.onChange(e.target.checked, 'mobile')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="mobile">
                      Mobile App
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={active}
                      id="active"
                      onChange={e=>this.onChange(e.target.checked, 'active')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="active">
                      Active
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={admin}
                      id="admin"
                      onChange={e=>this.onChange(e.target.checked, 'admin')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="admin">
                      Admin
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={location}
                      id="location"
                      onChange={e=>this.onChange(e.target.checked, 'location')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="location">
                      Track Location
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={calls}
                      id="calls"
                      onChange={e=>this.onChange(e.target.checked, 'calls')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="calls">
                      Track Calls
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={sms}
                      id="sms"
                      onChange={e=>this.onChange(e.target.checked, 'sms')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="sms">
                      Track SMS
                    </label>
                  </div>
                </FormGroup>
              </Col>
              <Col lg="3">
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={contacts}
                      id="contacts"
                      onChange={e=>this.onChange(e.target.checked, 'contacts')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="contacts">
                      Track Contacts
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row style={{border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7}}>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-password"
                    style={{color: validation.password===false?'red':null}}
                  >
                    <Required />Password
                  </label>
                  <InputGroup className="input-group-alternative">
                    <Input placeholder="Password" type={show?'text':'password'} onChange={e=>this.onChange(e.target.value, 'password')} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <span
                          className="hover-link"
                          style={{fontSize: 12, cursor: 'pointer'}}
                          onClick={()=>this.setState({show: !show})}>
                          {show?'hide':'show'}
                        </span>
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg="6" style={{alignSelf: 'center'}}>
                <FormGroup style={{margin: 0}}>
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <Input
                      className="custom-control-input"
                      checked={change_password}
                      id="change_password"
                      onChange={e=>this.onChange(e.target.checked, 'change_password')}
                      type="checkbox"
                    />
                    <label className="custom-control-label" htmlFor="change_password">
                      Force user to change Password at first login
                    </label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="success" type="button" disabled={saving} size="sm"
              onClick={this.onSave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddUser;
