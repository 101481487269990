import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from 'react-select';
import { getCustomer, updateCustomer }  from 'services/customerService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";

const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Customer extends React.Component {
	constructor(props) {
		super(props);
    const {location: state} = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      customer: state&&state.customer?state.customer:null,
      validation: {},
      roles: [],
      superiors: [],
      states: [],
      territories: [],
      zones: [],
      axes: [],
      id: props.match.params.id,
      products: []
    };
	}

  componentDidMount() {
    this.getCustomer();
  }

  getCustomer = () => {
    const {id} = this.state
    this.setState({loading: true});
    getCustomer(id)
      .then((res) => {console.log(res)
        this.setState({
          loading: false,
          customer: {...res.customer, products: res.customer.products.map(p=>({label:p.name, value:p.id}))},
          initialCustomer: {...res.customer, products: res.customer.products.map(p=>({label:p.name, value:p.id}))},
          roles: res.roles,
          states: res.states,
          superiors: res.superiors,
          territories: res.territories,
          zones: res.zones,
          axes: res.axes,
          products: res.products.map(p=>({label:p.name, value:p.id})),
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleEdit = () => {
    const {initialCustomer} = this.state
    this.setState({edit: !this.state.edit, customer: {...initialCustomer}});
  }

  togglePassword = () => {
    this.setState({change: !this.state.change});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {customer, validation} = this.state;
    const {name, phone, address, products, id} = customer;
    await this.setState({
      validation: {
        ...validation,
        name: name!==''&&name!==null,
        phone: phone!==''&&phone!==null,
        address: address!==''&&address!==null,
        products: products!==null&&products.length>0
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveCustomer();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  saveCustomer = () => {
    this.setState({saving: true});
    const {customer} = this.state;
    updateCustomer({...customer, products: customer.products.map(p=>p.value)})
      .then(res => {
          console.log(res)
          this.setState({
            saving: false,
            edit: false,
            customer: {...res.customer, products: res.customer.products.map(p=>({label:p.name, value:p.id}))},
            initialCustomer: {...customer},
            //customer: res.customer
          });
          this.showToast('Customer Updated')
      },
    	error => {
    		console.log(error)
        this.setState({saving: false});
    	});
  }

  validationRules = field => {
    if(field==='name'){
      return 'Name is required';
    } else if(field==='address'){
      return 'Address is required';
    } else if(field==='phone'){
      return 'Phone is required';
    } else if(field==='products'){
      return 'Product(s) is required';
    }
  }

  onChange = (e, state) => {console.log(e)
    const {customer} = this.state
    if(state==='state_id'){
      this.setState({
        customer: {...customer, territory_id: '', axis_id: '', zone_id: '', [state]: e}
      })
    } else if(state==='territory_id'){
      this.setState({customer: {...customer, axis_id: '', zone_id: '', [state]: e}})
    } else if(state==='axis_id'){
      this.setState({customer: {...customer, zone_id: '', [state]: e}})
    } else if(state==='status'){
      this.setState({customer: {...customer, status: e?'Active':'Inactive'}})
    } else {
      this.setState({customer: {...customer, [state]: e}})
    }
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state.customer;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          console.log(res)
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes,
            zones: res.zones
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  render() {
    const {customer, loading, validation, edit, roles, superiors, states, territories, zones, axes,
      initialCustomer, filtering, saving, change, products} = this.state
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: 'hsl(0,0%,95%)',
        boxShadow: '1px 1px 1px rgba(50, 50, 50, 0.1)'
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
      }
    }

    const googleMaps = (customer) => {
      var win = window.open(`http://www.google.com/maps/place/${customer.latitude},${customer.longitude}`, '_blank');
      win.focus();
    }

    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/customers"
                className="white-link"
                style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                <span>Back to Customers</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {customer&&<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                {customer&&<CardBody className="pt-0 pt-md-0">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">{customer.visits_count||0}</span>
                          <span className="description">Visit{customer.visits_count===1?'':'s'}</span>
                        </div>
                        <div>
                          <span className="heading">{customer.sales_count||0}</span>
                          <span className="description">Sale{customer.sales_count===1?'':'s'}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3 className="hover-icon">
                      {customer.name}
                      {customer.longitude!==null&&<i id="map" style={{marginLeft: 7}} className="fa fa-map-marker-alt" onClick={()=>googleMaps(customer)}/>}
                      {customer.longitude&&<UncontrolledTooltip placement="right" target="map">
                        View
                      </UncontrolledTooltip>}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {customer.address}
                    </div>
                    {customer.user_name&&<div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Assigned to: <span style={{fontWeight: 'bold'}}>{customer.user_name}</span>
                    </div>}
                    {customer.last_visit!==null&&<div className="h5 mt-1">
                      Last visit: {moment(customer.last_visit.created_at).calendar(null, {sameElse: 'MMM D, YYYY'})}
                    </div>}
                    {customer.last_sale!==null&&<div className="h5 mt-1">
                      Last sale: {moment(customer.last_sale.created_at).calendar(null, {sameElse: 'MMM D, YYYY'})}
                    </div>}
                    <hr className="my-4" />
                  </div>
                </CardBody>}
              </Card>
            </Col>}
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                {saving&&<SpinDiv text={'Saving...'} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{initialCustomer&&initialCustomer.name}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {customer&&<Button
                        color={edit?'secondary':"success"}
                        onClick={this.toggleEdit}
                        size="sm"
                      >
                        {edit?'Discard Changes':'Edit'}
                      </Button>}
                    </Col>
                  </Row>
                </CardHeader>
                {customer&&<CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-2">
                      Customer information
                    </h6>
                    <div>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                              style={{color: validation.name===false?'red':null}}
                            >
                              <Required />Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={customer.name}
                              disabled={!edit}
                              placeholder={edit?'Name':null}
                              id="input-name"
                              onChange={e=>this.onChange(e.target.value, 'name')}
                              //bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                              style={{color: validation.phone===false?'red':null}}
                            >
                              <Required />Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={customer.phone||''}
                              disabled={!edit}
                              id="input-phone"
                              onChange={e=>this.onChange(e.target.value, 'phone')}
                              placeholder={edit?'Phone':null}
                              //bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                              style={{color: validation.address===false?'red':null}}
                            >
                              <Required />Address
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={customer.address||''}
                              disabled={!edit}
                              id="input-address"
                              onChange={e=>this.onChange(e.target.value, 'address')}
                              placeholder={edit?'Address':null}
                              //bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-products"
                              style={{color: validation.products===false?'red':null}}
                            >
                              <Required />Product(s)
                            </label>
                            <Select
                              isMulti
                              isDisabled={!edit}
                              placeholder="Select Product(s)"
                              styles={customStyles}
                              value={customer.products}
                              onChange={e=>this.onChange(e, 'products')}
                              options={products}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              State
                            </label>
                            <Input value={customer.state_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit}
                              onChange={async (e)=>{
                                await this.onChange(e.target.value, 'state_id');
                                await this.filter();
                              }}>
                              <option value=''></option>
                              {states.map((s)=><option value={s} key={s}>{s}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Territory
                            </label>
                            <Input value={customer.territory_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit}
                              onChange={async(e)=>{
                                await this.onChange(e.target.value, 'territory_id');
                                await this.filter();
                              }}>
                              <option value=''>{filtering?'loading...':''}</option>
                              {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Axis
                            </label>
                            <Input value={customer.axis_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit}
                              onChange={async(e)=>{
                                await this.onChange(e.target.value, 'axis_id');
                                await this.filter();
                              }}>
                              <option value=''>{filtering?'loading...':''}</option>
                              {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Zone
                            </label>
                            <Input disabled={filtering||!edit} value={customer.zone_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} onChange={e=>this.onChange(e.target.value, 'zone_id')}>
                              <option value=''>{filtering?'loading...':''}</option>
                              {zones.map((z)=><option value={z} key={z}>{z}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={customer.status==="Active"||false}
                                disabled={!edit}
                                id="status"
                                onChange={e=>this.onChange(e.target.checked, 'status')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="status">
                                {customer.status==="Active"?'Active':'Inactive'}
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {edit&&<div style={{padding: '1rem'}}>
                      <Button color="success" type="button" disabled={saving}
                        onClick={this.onSave}
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
                        Save
                      </Button>
                    </div>}
                  </Form>
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Customer;
