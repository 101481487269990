import React from "react";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
import { Link } from "react-router-dom";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  //CardFooter,
  //NavItem,
  //NavLink,
  //Nav,
  //Progress,
  Table,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2
} from "variables/charts.jsx";
import "views/index.css";
import { getProducts }  from 'services/productService';
import AddProduct from "./AddProduct.jsx";
import EditProduct from "./EditProduct.jsx";
import DeleteProduct from "./DeleteProduct.jsx";
import SpinDiv from "components/SpinDiv.jsx";
import "views/index.css";

class Products extends React.Component {
  state = {
    products: [],
    page: 1,
  	total: 0,
    search: '',
    product: 'All Products'
   };

  componentDidMount() {
    this.getProducts();
  }

  getProducts = () => {
    const {page, search} = this.state;
    this.setState({loading: true});
    getProducts({page, search})
      .then((res) => {
        this.setState({
          products: res.products.data,
          page: res.products.current_page,
          total: res.products.total,
          loading: false
        })
      },
      error => {console.log(error)
        this.setState({loading: false});
      });
  }

  toggleAdd = () => {
    this.setState({add: !this.state.add});
  }

  toggleEdit = (editProduct) => {
    this.setState({editProduct});
  }

  toggleDelete = (deleteProduct) => {
    this.setState({deleteProduct});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  render() {
    const {add, loading, showFilter, search, product, products, editProduct, deleteProduct} = this.state;

      return (
        <>
          <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
            <Container fluid>
              <div className="header-body">
                <Link
                  to="/admin/settings"
                  className="white-link"
                  style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                  <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                  <span>Back to Settings</span>
                </Link>
              </div>
            </Container>
          </div>
          {/* Page content */}
          {add&&
            <AddProduct
              saved={this.getProducts}
              toggle={this.toggleAdd}
              add={add}
              product={product}
            />}
          {editProduct&&
            <EditProduct
              saved={this.getProducts}
              editProduct={editProduct}
              product={product}
              toggle={()=>this.setState({editProduct: null})}
            />}
          {deleteProduct&&
            <DeleteProduct
              saved={this.getProducts}
              deleteProduct={deleteProduct}
              product={product}
              toggle={()=>this.setState({deleteProduct: null})}
            />}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  {loading&&<SpinDiv text={'Loading...'} />}
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Products </h3>
                      </div>
                      <div style={{display: 'flex'}}>
                        {!showFilter&&<Button
                          color="warning"
                          onClick={this.toggleFilter}
                          size="sm"
                          style={{marginRight: 10}}
                        >
                          Filter
                        </Button>}
                        <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5}}
                          value={search}
                          onChange={e=>this.onChange(e.target.value, 'search')}
                          onKeyUp={(e) => {if(e.key==='Enter'){this.getProducts();}}} />
                        <Button className="btn-icon btn-2" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getProducts}>
                          <i className="fa fa-search" />
                        </Button>
                        <Button
                          color="success"
                          onClick={this.toggleAdd}
                          size="sm"
                        >
                          Add Product
                        </Button>
                      </div>
                    </Row>
                  </CardHeader>
                  {showFilter&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <span style={{marginRight: 10, fontSize: 14}}>Filter Users: </span>
                      <Input value={product} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'state')}>
                        <option>All Roles</option>
                        {products.map((s)=><option value={s} key={s}>{s}</option>)}
                      </Input>
                     </div>
                    <Button
                      color="warning"
                      onClick={this.toggleFilter}
                      size="sm"
                      style={{marginRight: 10}}
                    >
                      Hide Filters
                    </Button>
                  </div>}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Number of Customers</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {products.map((product) => {
                        return (
                          <tr key={product.id} className="hover-list">
                            <th scope="row">{product.name}</th>
                            <td>{product.customers_count}</td>
                            <td>
                              <Button
                                color="secondary"
                                onClick={() => this.toggleEdit(product)}
                                size="sm"
                              >
                                Edit
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => this.toggleDelete(product)}
                                size="sm"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {!loading&&products.length<1&&
                  <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                    <i className="fa fa-ban" style={{marginRight: 5}}/>
                    No product found
                  </div>}
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }

  export default Products;
