import Index from "views/Index.jsx";
import Users from "views/users/index.jsx";
//import Students from "views/examples/Students.jsx";
import Customers from "views/customers/index.jsx";
//import Modules from "views/examples/Modules.jsx";
//import Results from "views/examples/Results.jsx";
//import Articles from "views/examples/Articles.jsx";
import Settings from "views/settings/index.jsx";
import Sales from "views/sales/index.jsx";
import Visits from "views/visits/index.jsx";
import Targets from "views/targets/index.jsx";
import Tracking from "views/tracking/index.jsx";
import Messages from "views/messages/index.jsx";
//import Profile from "views/examples/Profile.jsx";
//import Maps from "views/examples/Maps.jsx";
//import Tables from "views/examples/Tables.jsx";
//import Icons from "views/examples/Icons.jsx";

var routes = [
  {
    path: "/home",
    name: "Home",
    icon: "fas fa-home text-green",
    component: Index,
    layout: "/admin"
  },
  {
    path: "/users",
    name: "Users",
    icon: "fas fa-user-tie text-blue",
    component: Users,
    layout: "/admin",
    admin: true
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "fas fa-user-friends text-info",
    component: Customers,
    layout: "/admin",
    admin: true
  },
  {
    path: "/visits",
    name: "Visits",
    icon: "fas fa-exchange-alt text-orange",
    component: Visits,
    layout: "/admin"
  },
  {
    path: "/sales",
    name: "Sales",
    icon: "fas fa-chart-bar text-black",
    component: Sales,
    layout: "/admin"
  },
  {
    path: "/targets",
    name: "Targets",
    icon: "fas fa-percent text-pink",
    component: Targets,
    layout: "/admin"
  },
  {
    path: "/tracking",
    name: "Tracking",
    icon: "fas fa-map-marked-alt text-purple",
    component: Tracking,
    layout: "/admin"
  },

  /*{
    path: "/modules",
    name: "Learning Modules",
    icon: "ni ni-hat-3 text-green",
    component: Modules,
    layout: "/admin"
  },
  {
    path: "/results",
    name: "Results",
    icon: "fas fa-tasks text-orange",
    component: Results,
    layout: "/admin"
  },
  {
    path: "/articles",
    name: "Articles",
    icon: "ni ni-single-copy-04 text-blue",
    component: Articles,
    layout: "/admin"
  },*/
  {
    path: "/messages",
    name: "Messages",
    icon: "ni ni-email-83 text-indigo",
    component: Messages,
    layout: "/admin"
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings text-red",
    component: Settings,
    layout: "/admin",
    admin: true
  },
  /*{
    path: "/icons",
    name: "Icons",
    icon: "ni ni-planet text-blue",
    component: Icons,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "ni ni-pin-3 text-orange",
    component: Maps,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin"
  }*/
];
export default routes;
