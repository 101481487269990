import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const saleService = {
    addSale,
    getSales,
    updateSale,
    deleteSale,
    exportSales
};

export function getSales(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}sales`, requestOptions).then(authService.handleResponse);
}

export function addSale(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addsale`, requestOptions).then(authService.handleResponse);
}

export function updateSale(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatesale/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteSale(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletesale/${id}`, requestOptions).then(authService.handleResponse);
}

export function exportSales(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}exportsales`, requestOptions).then(authService.handleResponse);
}
