import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { getUser, updateUser }  from 'services/userService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";
import ChangePassword from "./ChangePassword.jsx";

const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class User extends React.Component {
	constructor(props) {
		super(props);
    const {location: state} = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      user: state&&state.user?state.user:null,
      validation: {},
      roles: [],
      superiors: [],
      states: [],
      territories: [],
      zones: [],
      teams: [],
      axes: [],
      id: props.match.params.id
    };
	}

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
    const {id} = this.state
    this.setState({loading: true});
    getUser(id)
      .then((res) => {console.log(res)
        this.setState({
          loading: false,
          user: res.user,
          initialUser: {...res.user},
          roles: res.roles,
          states: res.states,
          superiors: res.superiors,
          territories: res.territories,
          zones: res.zones,
          teams: res.teams,
          axes: res.axes
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleEdit = () => {
    const {initialUser} = this.state
    this.setState({edit: !this.state.edit, user: {...initialUser}});
  }

  togglePassword = () => {
    this.setState({change: !this.state.change});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {user, validation} = this.state;
    const {firstname, lastname, email, password, id} = user;
    await this.setState({
      validation: {
        ...validation,
        firstname: firstname!==''&&firstname!==null,
        lastname: (lastname!==''&&lastname!==null)||id===2,
        email: emailReg.test(email)
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveUser();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  saveUser = () => {
    this.setState({saving: true});
    const {user} = this.state;
    updateUser(user)
      .then(res => {
          console.log(res)
          this.setState({saving: false});
          this.setState({edit: false, user: res.user});
          this.showToast('User Updated')
      },
    	error => {
    		console.log(error)
        if(error.email){
          this.showToast('A user with this Email address already exixts')
        }
        if(error.phone){
          this.showToast('A user with this Phone number already exixts')
        }
        if(error.staffid){
          this.showToast('A user with this Staff ID already exixts')
        }
        this.setState({saving: false});
    	});
  }

  validationRules = field => {
    if(field==='firstname'){
      return 'First name is required';
    } else if(field==='lastname'){
      return 'Last name is required';
    } else if(field==='email'){
      return 'Email is invalid';
    }
  }

  onChange = (e, state) => {
    const {user} = this.state
    if(state==='state_id'){
      this.setState({
        user: {...user, territory_id: '', axis_id: '', zone_id: '', [state]: e}
      })
    } else if(state==='territory_id'){
      this.setState({user: {...user, axis_id: '', zone_id: '', [state]: e}})
    } else if(state==='axis_id'){
      this.setState({user: {...user, zone_id: '', [state]: e}})
    } else {
      this.setState({user: {...user, [state]: e}})
    }
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state.user;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          console.log(res)
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes,
            zones: res.zones
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  render() {
    const {user, loading, validation, edit, roles, superiors, states, territories, zones, axes,
      initialUser, filtering, saving, change, teams} = this.state
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/users"
                className="white-link"
                style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                <span>Back to Users</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {change&&
          <ChangePassword
            toggle={this.togglePassword}
            user={user}
            change={change}
          />}
        <Container className="mt--7" fluid>
          <Row>
            {user&&<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <Row className="justify-content-center" style={{display: 'none'}}>
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          src={user&&user.picture?user.picture:require("assets/img/brand/user.jpg")}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
                <CardHeader className="text-center border-0 pt-4 pt-md-4 pb-0 pb-md-0">
                  {user&&<div className="d-flex justify-content-center">
                    <a onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={user.picture?user.picture:require("assets/img/brand/user.jpg")}
                        style={{maxWidth: 200}}
                      />
                    </a>
                  </div>}
                </CardHeader>
                {user&&<CardBody className="pt-0 pt-md-0">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">{user.visits_count||0}</span>
                          <span className="description">Visit{user.visits_count===1?'':'s'}</span>
                        </div>
                        <div>
                          <span className="heading">{user.sales_count||0}</span>
                          <span className="description">Sale{user.sales_count===1?'':'s'}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {user.name}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {user.email}
                    </div>
                    {user.last_visit!==null&&<div className="h5 mt-1">
                      Last visit: {moment(user.last_visit).calendar(null, {sameElse: 'MMM D, YYYY'})}
                    </div>}
                    {user.last_sale!==null&&<div className="h5 mt-1">
                      Last sale: {moment(user.last_sale).calendar(null, {sameElse: 'MMM D, YYYY'})}
                    </div>}
                    <hr className="my-4" />
                    {!change&&<Button color="success" type="button" disabled={loading} size="sm"
                      onClick={this.togglePassword}
                      style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
                      Change Password
                    </Button>}
                  </div>
                </CardBody>}
              </Card>
            </Col>}
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                {saving&&<SpinDiv text={'Saving...'} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{initialUser&&initialUser.name}</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {user&&<Button
                        color={edit?'secondary':"success"}
                        onClick={this.toggleEdit}
                        size="sm"
                      >
                        {edit?'Discard Changes':'Edit Profile'}
                      </Button>}
                    </Col>
                  </Row>
                </CardHeader>
                {user&&<CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-2">
                      User information
                    </h6>
                    <div>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                              style={{color: validation.firstname===false?'red':null}}
                            >
                              <Required />First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.firstname}
                              disabled={!edit}
                              placeholder={edit?'First name':null}
                              id="input-first-name"
                              onChange={e=>this.onChange(e.target.value, 'firstname')}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                              style={{color: validation.lastname===false?'red':null}}
                            >
                              <Required />Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.lastname||''}
                              disabled={!edit}
                              placeholder={edit?'Last name':null}
                              id="input-last-name"
                              onChange={e=>this.onChange(e.target.value, 'lastname')}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-staffid"
                            >
                              Team
                            </label>
                            <Input disabled={!edit} value={user.team_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} onChange={e=>this.onChange(e.target.value, 'team_id')}>
                              <option value=''></option>
                              {teams.map((r)=><option value={r} key={r}>{r}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                              style={{color: validation.email===false?'red':null}}
                            >
                              <Required />Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.email||''}
                              disabled={!edit}
                              id="input-email"
                              onChange={e=>this.onChange(e.target.value, 'email')}
                              placeholder={edit?'Email':null}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.phone||''}
                              disabled={!edit}
                              id="input-phone"
                              onChange={e=>this.onChange(e.target.value, 'phone')}
                              placeholder={edit?'Phone':null}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-alt-phone"
                            >
                              Alternative Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.altphone||''}
                              disabled={!edit}
                              id="input-alt-phone"
                              onChange={e=>this.onChange(e.target.value, 'altphone')}
                              placeholder={edit?'Alternative Phone':null}
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Role
                            </label>
                            <Input disabled={!edit} value={user.role_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} onChange={e=>this.onChange(e.target.value, 'role_id')}>
                              <option value=''></option>
                              {roles.map((r)=><option value={r} key={r}>{r}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              State
                            </label>
                            <Input value={user.state_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit}
                              onChange={async (e)=>{
                                await this.onChange(e.target.value, 'state_id');
                                await this.filter();
                              }}>
                              <option value=''></option>
                              {states.map((s)=><option value={s} key={s}>{s}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Territory
                            </label>
                            <Input value={user.territory_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit}
                              onChange={async(e)=>{
                                await this.onChange(e.target.value, 'territory_id');
                                await this.filter();
                              }}>
                              <option value=''>{filtering?'loading...':''}</option>
                              {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Superior
                            </label>
                            <Input disabled={!edit} value={user.superior_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} onChange={e=>this.onChange(e.target.value, 'superior_id')}>
                              <option value=''></option>
                              {superiors.map((s)=><option value={s.id} key={s.id}>{s.name}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Axis
                            </label>
                            <Input value={user.axis_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit}
                              onChange={async(e)=>{
                                await this.onChange(e.target.value, 'axis_id');
                                await this.filter();
                              }}>
                              <option value=''>{filtering?'loading...':''}</option>
                              {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Zone
                            </label>
                            <Input disabled={filtering||!edit} value={user.zone_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}} onChange={e=>this.onChange(e.target.value, 'zone_id')}>
                              <option value=''>{filtering?'loading...':''}</option>
                              {zones.map((z)=><option value={z} key={z}>{z}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-2">
                      ACCESS PERMISSIONS
                    </h6>
                    <div>
                      <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.active||false}
                                disabled={!edit}
                                id="active"
                                onChange={e=>this.onChange(e.target.checked, 'active')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="active">
                                {user.active?'Active':'Inactive'}
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.web||false}
                                disabled={!edit}
                                id="web"
                                onChange={e=>this.onChange(e.target.checked, 'web')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="web">
                                Web App
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.mobile||false}
                                disabled={!edit}
                                id="mobile"
                                onChange={e=>this.onChange(e.target.checked, 'mobile')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="mobile">
                                Mobile App
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.admin||false}
                                disabled={!edit}
                                id="admin"
                                onChange={e=>this.onChange(e.target.checked, 'admin')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="admin">
                                Admin
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-2">
                      TRACKING
                    </h6>
                    <div>
                      <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.location||false}
                                disabled={!edit}
                                id="location"
                                onChange={e=>this.onChange(e.target.checked, 'location')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="location">
                                Track Location
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.calls||false}
                                disabled={!edit}
                                id="calls"
                                onChange={e=>this.onChange(e.target.checked, 'calls')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="calls">
                                Track Calls
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.sms||false}
                                disabled={!edit}
                                id="sms"
                                onChange={e=>this.onChange(e.target.checked, 'sms')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="sms">
                                Track SMS
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.contacts||false}
                                disabled={!edit}
                                id="contacts"
                                onChange={e=>this.onChange(e.target.checked, 'contacts')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="contacts">
                                Track Contacts
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-2">Change Password</h6>
                    <div>
                      <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
                        <Col lg="12" style={{alignSelf: 'center'}}>
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.change_password||false}
                                disabled={!edit}
                                id="change_password"
                                onChange={e=>this.onChange(e.target.checked, 'change_password')}
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="change_password">
                                Force user to change password at next login
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {edit&&<div style={{padding: '1rem'}}>
                      <Button color="success" type="button" disabled={saving}
                        onClick={this.onSave}
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
                        Save
                      </Button>
                    </div>}
                  </Form>
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default User;
