/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import moment from "moment";

import UserPassword from "./UserPassword.jsx";
// core components
import UserHeader from "components/Headers/UserHeader.jsx";
import { getUser, profile }  from 'services/authService';

class Profile extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      loading: false,
      edit: false,
      change: false,
      user: null,
      validation: {},
      roles: [],
      superiors: [],
      states: [],
      territories: [],
      zones: [],
      axes: []
    };
	}

  componentDidMount() {
    this.setState({user: getUser()})
    this.getUser();
  }

  getUser = () => {
    const {id} = this.state
    this.setState({iloading: true});
    profile()
      .then((res) => {
        if (res.user) {
            const newuser = {...res.user, token: res.token}
            localStorage.setItem('user', JSON.stringify(newuser));
            this.setState({ user: newuser })
        }
        this.setState({iloading: false})
      },
      error => {
        this.setState({iloading: false});
      });
  }

  togglePassword = () => {
    this.setState({change: !this.state.change});
  }

  render() {
    const {user, loading, change, roles, states, zones} = this.state
    return (
      <>
        <UserHeader />
        {/* Page content */}
        {change&&
          <UserPassword
            toggle={this.togglePassword}
            user={user}
            change={change}
            login={() => {}}
          />}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                <CardHeader className="text-center border-0 pt-4 pt-md-4 pb-0 pb-md-0">
                  {user&&<div className="d-flex justify-content-center">
                    <a onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={user.picture?user.picture:require("assets/img/brand/user.jpg")}
                        style={{maxWidth: 200}}
                      />
                    </a>
                  </div>}
                </CardHeader>
                {user&&<CardBody className="pt-0 pt-md-0">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">{user.visits_count||0}</span>
                          <span className="description">Visit{user.visits_count===1?'':'s'}</span>
                        </div>
                        <div>
                          <span className="heading">{user.sales_count||0}</span>
                          <span className="description">Sale{user.sales_count===1?'':'s'}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      {user.name}
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      {user.email}
                    </div>
                    {user.last_visit!==null&&<div className="h5 mt-1">
                      Last visit: {moment(user.last_visit).calendar(null, {sameElse: 'MMM D, YYYY'})}
                    </div>}
                    {user.last_sale!==null&&<div className="h5 mt-1">
                      Last sale: {moment(user.last_sale).calendar(null, {sameElse: 'MMM D, YYYY'})}
                    </div>}
                    <hr className="my-4" />
                    {!change&&<Button color="success" type="button" disabled={loading} size="sm"
                      onClick={this.togglePassword}
                      style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
                      Change Password
                    </Button>}
                  </div>
                </CardBody>}
              </Card>
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">My Profile</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                    </Col>
                  </Row>
                </CardHeader>
                {user&&<CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-2">
                      User information
                    </h6>
                    <div>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.firstname}
                              disabled
                              id="input-first-name"
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.lastname||''}
                              disabled
                              id="input-last-name"
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-staffid"
                            >
                              Team
                            </label>
                            <Input disabled value={user.team_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}>
                              <option value=''></option>
                              {<option value={user.team_id}>{user.team_id}</option>}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.email||''}
                              disabled
                              id="input-email"
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.phone||''}
                              disabled
                              id="input-phone"
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-alt-phone"
                            >
                              Alternative Phone
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={user.altphone||''}
                              disabled
                              id="input-alt-phone"
                              bsSize="sm"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Role
                            </label>
                            <Input disabled value={user.role_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}>
                              <option value=''></option>
                              {<option value={user.role_id}>{user.role_id}</option>}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              State
                            </label>
                            <Input value={user.state_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled>
                              <option value=''></option>
                              {<option value={user.state_id}>{user.state_id}</option>}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Territory
                            </label>
                            <Input value={user.territory_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled>
                              <option value=''></option>
                              {<option value={user.territory_id}>{user.territory_id}</option>}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Superior
                            </label>
                            <Input disabled value={user.superior_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}>
                              <option value=''></option>
                              {<option value={user.superior_id}>{user.superior_name}</option>}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Axis
                            </label>
                            <Input value={user.axis_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled>
                              <option value=''></option>
                              {<option value={user.axis_id}>{user.axis_id}</option>}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Zone
                            </label>
                            <Input disabled value={user.zone_id||''} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}>
                              <option value=''></option>
                              {<option value={user.zone_id}>{user.zone_id}</option>}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Address */}
                    <h6 className="heading-small text-muted mb-2">
                      ACCESS PERMISSIONS
                    </h6>
                    <div>
                      <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.web||false}
                                disabled
                                id="web"
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="web">
                                Web App
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.mobile||false}
                                disabled
                                id="mobile"
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="mobile">
                                Mobile App
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-2">Change Password</h6>
                    <div>
                      <Row style={{border: '1px #eee solid', padding: '10px 5px 12px', margin: '0 2px 15px', borderRadius: 7}}>
                        <Col lg="12" style={{alignSelf: 'center'}}>
                          <FormGroup style={{margin: 0}}>
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <Input
                                className="custom-control-input"
                                checked={user.change_password||false}
                                disabled
                                id="change_password"
                                type="checkbox"
                              />
                              <label className="custom-control-label" htmlFor="change_password">
                                Change password at next login
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Profile;
