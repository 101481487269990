import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';

import { updateTeam }  from 'services/teamService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";

class EditTeam extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      zone: props.editTeam,
      loading: false,
      states: props.states,
      search: '',
      territories: [props.editTeam.territory_id],
      axes: [props.editTeam.axis_id],
      state_id: '',
      territory_id: '',
      axis_id: '',
      validation: {},
      name: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
    this.filter()
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {zone, validation} = this.state;
    const {name, state_id, territory_id, axis_id} = zone;
    await this.setState({
      validation: {
        ...validation,
        name: name!=='',
        state_id: state_id!=='',
        territory_id: territory_id!==''
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveTeam();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if(field==='name'){
      return 'Name is required';
    } else if(field==='state_id'){
      return 'State is required';
    } else if(field==='territory_id'){
      return 'Territory is required';
    }
  }

  saveTeam = () => {
    this.setState({loading: true});
    const {name, state_id, territory_id, axis_id, id} = this.state.zone;
    updateTeam({name, state_id, territory_id, axis_id, id})
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('Team Updated')
      },
    	error => {
    		console.log(error)
        if(error.name){
          this.showToast('A zone with this name already exists')
        }
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    if(state==='state_id'){
      this.setState({territory_id: '', axis_id: ''})
    }
    if(state==='territory_id'){
      this.setState({axis_id: ''})
    }
    const {zone} = this.state
    this.setState({zone: {...zone, [state]: e}})
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state.zone;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          console.log(res)
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  render() {
    const { editTeam, toggle } = this.props
    const {states, territories, axes, loading, filtering, validation, zone} = this.state;
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={editTeam!=null}
          toggle={() => !loading&&toggle}
          style={{maxWidth: 600}}
        >
          {loading&&<SpinDiv text={'Saving...'} />}
          {filtering&&<SpinDiv text={'Loading...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Edit Team
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{color: validation.name===false?'red':null}}
                  >
                    <Required />Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    value={zone.name||''}
                    id="input-name"
                    onChange={e=>this.onChange(e.target.value.trim().replace(/[^a-zA-Z0-9]/,''), 'name')}
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                    style={{color: validation.state_id===false?'red':null}}
                  >
                    <Required />State
                  </label>
                  <Input type="select" bsSize="sm"
                    style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    value={zone.state_id||''}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'state_id');
                      await this.filter();
                    }}
                  >
                    <option disabled value=''>Select State</option>
                    {states.map((s)=><option value={s} key={s}>{s}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                    style={{color: validation.territory_id===false?'red':null}}
                  >
                    <Required />Territory
                  </label>
                  <Input type="select" bsSize="sm"
                    style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    value={zone.territory_id||''}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'territory_id');
                      await this.filter();
                    }}
                  >
                    <option disabled value=''>
                      {filtering&&zone.territory_id===''?'Loading...':'Select Territory'}
                    </option>
                    {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                    style={{color: validation.axis_id===false?'red':null}}
                  >
                    Axis
                  </label>
                  <Input type="select" bsSize="sm"
                    style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    value={zone.axis_id||''}
                    onChange={e=>this.onChange(e.target.value, 'axis_id')}>
                    <option disabled value=''>
                      {filtering&&zone.axis_id===''?'Loading...':'Select Axis'}
                    </option>
                    {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="success" type="button" disabled={loading} size="sm"
              onClick={this.onSave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default EditTeam;
