import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { toast } from 'react-toastify';

import { addTarget, getTargets }  from 'services/targetService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";
import ReactDatetime from "react-datetime";
import moment from "moment";


//const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg=/^([0]\d{10})$/;

class AddTarget extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      activeNav: 1,
      loading: false,
      search: '',
      states: props.states,
      products: props.products,
      product: 'All Products',
      territories: [],
      axes: [],
      zones: [],
      users: props.users,
      validation: {},
      status: '',
      active: '',
      user_id: '',
      product_id: '',
      superior_id: '',
      subordinate_id: '',
      quantity: '',
      fromdate: moment().startOf('month'),
      todate: moment().endOf('day')
    };

	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {quantity, validation} = this.state;
    await this.setState({
      validation: {
        ...validation,
        quantity: quantity!=='',
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveTarget();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if(field==='quantity'){
      return 'Quantity is required';
       }
  }

  saveTarget = () => {
    this.setState({loading: true});
    const {superior_id, subordinate_id, user_id, product_id, quantity, fromdate, todate, active} = this.state;
    addTarget({superior_id, subordinate_id, user_id, product_id, quantity, fromdate, todate, active} )
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('Target Created')
      },
    	error => {
    		console.log(error)
        if(error.phone){
          this.showToast('A target with this Phone number already exixts')
        }
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    if(state==='superior_id'){
      this.setState({subordinate_id: '', product_id: ''})
    }
    if(state==='subordinate_id'){
      this.setState({product_id: ''})
    }

    this.setState({[state]: e})
  }


  filter = () => {
    this.setState({filtering: true});
    const {superior_id, subordinate_id, product_id} = this.state;
    filter({superior_id, subordinate_id, product_id})
      .then(res => {
          this.setState({
            filtering: false,
            products: res.products,
            users: res.users

          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  dateFilter = (e, state) => {
    this.setState({[state]: e})

  }



  render() {
    const { addTarget, toggle} = this.props
    const {targets, saving, loading, active, validation, show, user_id, superior_id, subordinate_id, filtering, users,
       product_id, product, products, fromdate, todate} = this.state;
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={addTarget}
          toggle={() => !saving&&toggle}
          style={{maxWidth: 700}}
        >
          {loading&&<SpinDiv text={'Saving...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Target
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            <Row>
              <Col lg="6">
              <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                  >
                    Superior
                  </label>
                  <Input value={superior_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}

                    onChange={async e => {
                      await this.onChange(e.target.value, 'superior_id');

                    }}>
                    <option disabled value=''>Select Users</option>
                    {users.map((b)=><option value={b.id} key={b.id}>{b.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
              <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-subordinate"
                  >
                    Subordinate
                  </label>
                  <Input value={subordinate_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}

                    onChange={async e => {
                      await this.onChange(e.target.value, 'subordinate_id');

                    }}>
                    <option disabled value=''>Select Users</option>
                    {users.map((m)=><option value={m.id} key={m.id}>{m.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col lg="6">
              <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                  >
                    Product
                  </label>
                  <Input value={product_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}

                    onChange={async e => {
                      await this.onChange(e.target.value, 'product_id');
                     }}>
                    <option disabled value=''>Select Product</option>
                    {products.map((p)=><option value={p} key={p}>{p}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{color: validation.phone===false?'red':null}}
                  >
                    <Required />Quantity
                  </label>
                  <Input
                    style={{height: 31}}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={e=>this.onChange(e.target.value, 'quantity')}
                    placeholder="quantity"
                    type="number"
                  />
                </FormGroup>
              </Col>
             </Row>
             <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                  >
                    Start Date
                  </label>
                  <ReactDatetime
                        value={fromdate}
                        dateFormat={'MMM D, YYYY'}
                        closeOnSelect
                        onChange={e=>this.dateFilter(e, 'fromdate')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-filter'
                        }}
                        isValidDate={(current)=>{return (current.isBefore(todate)||current.isSame(todate))&&current.isBefore(moment());}}
                        timeFormat={false}
                      />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                  >
                    End Date
                  </label>
                  <ReactDatetime
                        value={todate}
                        dateFormat={'MMM D, YYYY'}
                        closeOnSelect
                        onChange={e=>this.dateFilter(e, 'todate')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-filter'
                        }}
                        isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                        timeFormat={false}
                      />
                </FormGroup>
              </Col>
             </Row>

            </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="success" type="button" disabled={saving} size="sm"
              onClick={this.onSave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddTarget;
