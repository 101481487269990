import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';

import { addSale }  from 'services/saleService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";

//const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg=/^([0]\d{10})$/;

class AddSale extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      activeNav: 1,
      loading: false,
      search: '',
      states: props.states,
      territories: props.territories,
      axes: props.axes,
      zones: props.zones,
      validation: {},
      name: '',
      address: '',
      phone: '',
      email: '',
      status: '',
      active: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {name, phone, validation} = this.state;
    await this.setState({
      validation: {
        ...validation,
        name: name!=='',
        phone: phoneReg.test(phone),
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveSale();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if(field==='name'){
      return 'Name is required';
    } else if(field==='phone'){
      return 'Phone no is required';
    }
  }

  saveSale = () => {
    this.setState({loading: true});
    const {name, phone, email, active} = this.state;
    addSale({name, phone, email, active})
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('Sale Created')
      },
    	error => {
    		console.log(error)
        if(error.phone){
          this.showToast('A sale with this Phone number already exixts')
        }
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }


  render() {
    const { addSale, toggle } = this.props
    const {saving,
      loading, active,
      validation, show} = this.state;
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={addSale}
          toggle={() => !saving&&toggle}
          style={{maxWidth: 700}}
        >
          {loading&&<SpinDiv text={'Saving...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Sale
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{color: validation.name===false?'red':null}}
                  >
                    <Required />Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-name"
                    onChange={e=>this.onChange(e.target.value, 'name')}
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-phone"
                    style={{color: validation.phone===false?'red':null}}
                  >
                    <Required />Phone No
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-phone"
                    onChange={e=>this.onChange(e.target.value, 'phone')}
                    placeholder="Phone No"
                    type="text"
                  />
                </FormGroup>
              </Col>
             </Row>
             <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                  >
                    Address
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-address"
                    onChange={e=>this.onChange(e.target.value, 'address')}
                    placeholder="Address"
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-email"
                    style={{color: validation.email===false?'red':null}}
                  >
                    Email
                  </label>
                  <Input
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-email"
                    onChange={e=>this.onChange(e.target.value, 'email')}
                    placeholder="Email"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>

            </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="success" type="button" disabled={saving} size="sm"
              onClick={this.onSave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddSale;
