import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

import "views/index.css";
import { addUser, getUsers, exportUsers, attendance }  from 'services/userService';
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddUser from "./AddUser.jsx";

class Users extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    roles: [],
    states: [],
    search: '',
    territories: [],
    axes: [],
    zones: [],
    teams: [],
    role: 'All Roles',
    state: 'All States',
    territory: 'All Territories',
    axis: 'All Axes',
    zone: 'All Zones',
    team: 'All Teams',
    superiors: [],
    users: [],
    fromdate: moment().startOf('month'),
    todate: moment()
  };

  componentDidMount() {
    this.getUsers();
  }

  componentWillMount() {
  }

  saveUser = (e) => {
    e.preventDefault();
    const {firstname, lastname, role_id, change_password} = this.state;
    addUser({firstname, lastname, role_id, change_password})
      .then(res => {
          this.setState({message : 'User added successfully.'});
          this.props.history.push('/users');
      },
    	error => {
    		console.log(error)
    	});
  }

  getUsers = () => {
    const {role, state, territory, axis, zone, team, page, rows, search} = this.state;
    this.setState({loading: true});
    getUsers({role, state, territory, axis, zone, team, page, rows, search})
      .then((res) => {
        this.setState({
          users: res.users.data,
          roles: res.roles,
          states: res.states,
          territories: res.territories,
          axes: res.axes,
          zones: res.zones,
          teams: res.teams,
          page: res.users.current_page,
          total: res.users.total,
          loading: false
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addUser: !this.state.addUser});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  toggleAttendance = () => {
    this.setState({attendance: !this.state.attendance});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  onSearch = () => {

  }

  onFilter = async (e, filter) => {
    if(filter==='state'){
      await this.setState({territory: 'All Territories', axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='territory'){
      await this.setState({axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='axis'){
      await this.setState({zone: 'All Zones'});
    }
    await this.setState({[filter]: e});
    await this.getUsers();
  }

  export = () => {
    const {role, state, territory, axis, zone, team, search} = this.state;
    this.setState({ loading: true });
    exportUsers({role, state, territory, axis, zone, team, search})
      .then(response => {
        let exportt = '';
        exportt = response.users.map(u => (
          {firstname: u.firstname, lastname: u.lastname, phone: u.phone, email: u.email, state: u.state, territory: u.territory, axis: u.axis, zone: u.zone, status: u.status?'Active':'Inactive', created_at: u.created_at, brand: u.brand, model: u.model, carrier: u.carrier, networktype: u.networktype }
        ));

        const theheader = ['firstname','lastname','phone','email','state','territory','axis','zone','status','created_at','brand','model','carrier','networktype'];
        const wch = [15,15,15,40,20,20,20,20,20,20,15,20,20,15];
        const cols = wch.map(h => {return ({wch: h});});
        const thedata = exportt.map(item => {
          return theheader.map(item2 => {
            return item[item2]
          })
        });
        const allofit = [theheader].concat(thedata);

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        ws['!cols'] = cols;
        XLSX.utils.book_append_sheet(wb, ws, 'Users');
        XLSX.writeFile(wb, 'users.xlsx');
        this.setState({
          loading: false
        });
      },
      error => {
        this.setState({ loading: false });
      });
  }

  formatA = (a) => {
    const time = moment(a['clockin']).format('H:mma');
    const location = `[${a.inlongitude}, ${a.inlatitude}]`;
    const phone = `${!a.inmodel.includes(a.inbrand)?`${a.inbrand} `:''}${a.inmodel}`;
    const imei = `${a.inimei}`;
    return `time: ${time}\nphone: ${phone}\nimei: ${imei}\nClick to view on map`
  }

  getMap = (item) => {
      return {f: `HYPERLINK("http://www.google.com/maps/place/${item.inlatitude},${item.inlongitude}","${this.formatA(item)}")`};
  }

  attendance = () => {
    const {role, state, territory, axis, zone, team, search, fromdate, todate} = this.state;
    this.setState({ loading: true });
    attendance({role, state, territory, axis, zone, team, search, fromdate: moment(fromdate).format('x')/1000, todate: moment(todate).format('x')/1000})
      .then(response => {//console.log(response)

        const theheader = ['Name'].concat(response.dates);
        const cols = theheader.map(h => {return ({wch: 28});});
        const thedata = response.users.map(item => {
          return theheader.map(item2 => {
            if(item2!=='Name'){
              return item[item2]?this.getMap(item[item2]):'N/A';
            }
            return item[item2];
          })
        });
        const allofit = [theheader].concat(thedata);

        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        ws['!cols'] = cols;
        XLSX.utils.book_append_sheet(wb, ws, 'Attendance');
        XLSX.writeFile(wb, 'attendance.xlsx');
        this.setState({
          loading: false
        });
      },
      error => {
        this.setState({ loading: false });
      });
  }

  onPage = async (page) => {
    await this.setState({page});
    await this.getUsers();
  }

  onDate = (e, filter) => {
    this.setState({[filter]: e});
  }

  render() {
    const {users, showFilter, role, roles, states, state, territories, territory, addUser, attendance,
      axes, axis, zones, zone, teams, team, total, page, rows, search, loading, superiors,
      todate, fromdate} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addUser&&
          <AddUser
            saved={this.getUsers}
            toggle={this.toggleModal}
            addUser={addUser}
            roles={roles}
            states={states}
            territories={territories}
            axes={axes}
            zones={zones}
            teams={teams}
            superiors={superiors}
          />}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Users <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div style={{display: 'flex', overflowX: 'auto', paddingBottom: 8}}>
                      {!showFilter&&<Button
                        color="warning"
                        onClick={this.toggleFilter}
                        size="sm"
                        style={{marginRight: 10}}
                      >
                        Filter
                      </Button>}
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={async(e) => {if(e.key==='Enter'){await this.setState({page:1});await this.getUsers();}}} />
                      <Button className="btn-icon btn-2" color="secondary" size="sm" style={{marginRight: 10}} onClick={async() => {await this.setState({page:1});await this.getUsers();}}>
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        onClick={() => this.toggleModal()}
                        size="sm"
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}
                      >
                        Add User
                      </Button>
                      {!attendance&&
                      <Button
                        color="warning"
                        onClick={this.toggleAttendance}
                        size="sm"
                        disabled={loading}
                        //style={{marginRight: 10}}
                      >
                        Attendance
                      </Button>}
                      <Button
                        color="success"
                        onClick={this.export}
                        size="sm"
                        disabled={loading}
                        //style={{marginRight: 10}}
                      >
                        Export
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {showFilter&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center', overflowX: 'auto'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Filter Users: </span>
                    <Input value={state} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'state')}>
                      <option>All States</option>
                      {states.map((s)=><option value={s} key={s}>{s}</option>)}
                    </Input>
                    {state!=='All States'&&
                    <Input value={territory} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'territory')}>
                      <option>All Territories</option>
                      {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                    </Input>}
                    {territory!=='All Territories'&&
                    <Input value={axis} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'axis')}>
                      <option>All Axes</option>
                      {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                    </Input>}
                    {axis!=='All Axes'&&
                    <Input value={zone} type="select" bsSize="sm"  style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'zone')}>
                      <option>All Zones</option>
                      {zones.map((z)=><option value={z} key={z}>{z}</option>)}
                    </Input>}
                    <Input value={role} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'role')}>
                      <option>All Roles</option>
                      {roles.map((r)=><option value={r} key={r}>{r}</option>)}
                    </Input>
                    <Input value={team} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'team')}>
                      <option>All Teams</option>
                      {teams.map((r)=><option value={r} key={r}>{r}</option>)}
                    </Input>
                  </div>
                  <Button
                    color="warning"
                    onClick={async ()=>{
                      this.toggleFilter();
                      await this.setState({role: 'All Roles'})
                      await this.onFilter('All States', 'state');
                    }}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Hide Filters
                  </Button>
                </div>}
                {attendance&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Select Dates: </span>
                    <ReactDatetime
                      value={fromdate}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e=>this.onDate(e, 'fromdate')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-filter'
                      }}
                      isValidDate={(current)=>{return (current.isBefore(todate)||current.isSame(todate))&&current.isBefore(moment());}}
                      timeFormat={false}
                    />
                    <span className="hide-mobile" style={{padding: '0 10px'}}>-</span>
                    <ReactDatetime
                      value={todate}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e=>this.onDate(e, 'todate')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-filter'
                      }}
                      isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                      timeFormat={false}
                    />
                    <Button
                      color="success"
                      onClick={async ()=>{
                        this.attendance();
                      }}
                      size="sm"
                      style={{marginLeft: 10, marginRight: 10}}
                    >
                      Download
                    </Button>
                  </div>
                  <Button
                    color="warning"
                    onClick={async ()=>{
                      this.toggleAttendance();
                    }}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Cancel
                  </Button>
                </div>}
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Role</th>
                      <th scope="col">Team</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, key) => {
                      return (
                        <tr key={key} className="hover-list" to="/"
                          onClick={() => {
                            this.props.history.push(`/admin/users/${user.id}`, {user})
                          }}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#p"
                                onClick={e => e.preventDefault()}
                              >
                                <img
                                  alt="..."
                                  src={user.picture||require("assets/img/brand/user.jpg")}
                                />
                              </a>
                              <Media>
                                <span className="mb-0 text-sm">
                                  {user.name}
                                </span>
                              </Media>
                            </Media>
                          </th>
                          <td>{user.email}</td>
                          <td>{user.phone}</td>
                          <td>{`${user.admin?'Administrator':''}${user.role_id||''}`}</td>
                          <td>{`${user.team_id||'-'}`}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              <i className={user.active?'bg-primary':'bg-danger'} />
                              {user.active?'Active':'Inactive'}
                            </Badge>
                          </td>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&users.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No user found
                </div>}
                {users.length>0&&<CardFooter className={`py-4 ${loading?'load-opacity':''}`}>
                  <CustomPagination page={page} total={total} rows={rows} onPage={this.onPage} />
                </CardFooter>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Users;
