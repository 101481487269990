import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const teamService = {
    addTeam,
    getTeams,
    updateTeam,
    deleteTeam
};

export function getTeams(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}teams`, requestOptions).then(authService.handleResponse);
}

export function addTeam(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addteam`, requestOptions).then(authService.handleResponse);
}

export function updateTeam(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateteam/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteTeam(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteteam/${id}`, requestOptions).then(authService.handleResponse);
}
