import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

import "views/index.css";
import { addSale, getSales, exportSales }  from 'services/saleService';
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddSale from "./AddSale.jsx";

class Sales extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 10,
  	total: 0,
    search: '',
    states: [],
    territories: [],
    axes: [],
    zones: [],
    products: [],
    users: [],
    state: 'All States',
    territory: 'All Territories',
    axis: 'All Axes',
    zone: 'All Zones',
    product: 'All Products',
    user: 'All Users',
    fromdate: moment().startOf('month'),
    todate: moment().endOf('day'),
    sales: []
  };

  componentDidMount() {
    this.getSales();
  }

  componentWillMount() {
  }

  saveSale = (e) => {
    e.preventDefault();
    const {name, phone, email, address} = this.state;
    addSale({name, phone, email, address})
      .then(res => {
          this.setState({message : 'Sale added successfully.'});
          this.props.history.push('/sales');
      },
    	error => {
    		console.log(error)
    	});
  }

  getSales = () => {
    const {state, territory, axis, zone, product, user, page, rows, search, fromdate, todate} = this.state;
    this.setState({loading: true});
    getSales({state, territory, axis, zone, product, user, page, rows, search, fromdate, todate})

      .then((res) => {
        this.setState({
          sales: res.sales.data,
          states: res.states,
          territories: res.territories,
          axes: res.axes,
          zones: res.zones,
          products: res.products,
          users: res.users,
          page: res.sales.current_page,
          total: res.sales.total,
          loading: false
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addSale: !this.state.addSale});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  onSearch = () => {

  }

  onFilter = async (e, filter) => {
    if(filter==='state'){
      await this.setState({territory: 'All Territories', axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='territory'){
      await this.setState({axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='axis'){
      await this.setState({zone: 'All Zones'});
    }
    await this.setState({[filter]: e});
    await this.getSales();
  }

  export = async () => {
    const {state, territory, axis, zone, product, user, search, fromdate, todate, total} = this.state;
    if(total<1){console.log(total)
      await toast.dismiss();
      await setTimeout(()=>this.showToast('No sales to export.'), 250);
    } else {
      this.setState({ loading: true });
      exportSales({state, territory, axis, zone, product, user, search, fromdate, todate})
        .then(response => {
          let exportt = '';
          exportt = response.sales.map(c => (
            {customer: c.name, product: c.product_id, quantity: c.quantity, date: c.saledate, user: c.user_name, state: c.state_id, territory: c.territory_id, axis: c.axis_id, zone: c.zone_id }
          ));

          const theheader = ['customer','product','quantity','date','user','state','territory','axis','zone'];
          const wch = [30,20,15,20,40,20,20,20,20];
          const cols = wch.map(h => {return ({wch: h});});
          const thedata = exportt.map(item => {
            return theheader.map(item2 => {
              return item[item2]
            })
          });
          const allofit = [theheader].concat(thedata);

          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          ws['!cols'] = cols;
          XLSX.utils.book_append_sheet(wb, ws, 'Sales');
          XLSX.writeFile(wb, 'sales.xlsx');
          this.setState({
            loading: false
          });
        },
        error => {
          this.setState({ loading: false });
        });
    }
  }

  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }

  onPage = async (page) => {
    await this.setState({page});
    await this.getSales();
  }

  render() {
    const {sales, showFilter, addSale, total, page, rows, search, loading, state, territory,
      axis, zone, product, user, states, territories, axes, zones, products, users, fromdate, todate} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        {addSale&&
          <AddSale
            saved={this.getSales}
            toggle={this.toggleModal}
            addSale={addSale}

          />}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      <h3 className="mb-0">Sales <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h3>
                    </div>
                    <div className="mobile-flex" style={{padding: '0 15px', alignItems: 'center'}}>
                      <ReactDatetime
                        value={fromdate}
                        dateFormat={'MMM D, YYYY'}
                        closeOnSelect
                        onChange={e=>this.onFilter(e, 'fromdate')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-filter'
                        }}
                        isValidDate={(current)=>{return (current.isBefore(todate)||current.isSame(todate))&&current.isBefore(moment());}}
                        timeFormat={false}
                      />
                      <span className="hide-mobile" style={{padding: '0 10px'}}>-</span>
                      <ReactDatetime
                        value={todate}
                        dateFormat={'MMM D, YYYY'}
                        closeOnSelect
                        onChange={e=>this.onFilter(e, 'todate')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-filter'
                        }}
                        isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                        timeFormat={false}
                      />
                    </div>
                    <div className="mobile-flex">
                      {!showFilter&&<Button
                        color="warning"
                        onClick={this.toggleFilter}
                        size="sm"
                        style={{marginRight: 10}}
                      >
                        Filter
                      </Button>}
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getSales();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getSales}>
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        color="success"
                        onClick={this.export}
                        size="sm"
                        disabled={loading}
                        //style={{marginRight: 10}}
                      >
                        Export
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {showFilter&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'scroll'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Filter Sales: </span>
                    <Input value={state} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'state')}>
                      <option>All States</option>
                      {states.map((s)=><option value={s} key={s}>{s}</option>)}
                    </Input>
                    {state!=='All States'&&
                    <Input value={territory} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'territory')}>
                      <option>All Territories</option>
                      {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                    </Input>}
                    {territory!=='All Territories'&&
                    <Input value={axis} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'axis')}>
                      <option>All Axes</option>
                      {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                    </Input>}
                    {axis!=='All Axes'&&
                    <Input value={zone} type="select" bsSize="sm"  style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'zone')}>
                      <option>All Zones</option>
                      {zones.map((z)=><option value={z} key={z}>{z}</option>)}
                    </Input>}
                    <Input value={product} type="select" bsSize="sm"  style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'product')}>
                      <option>All Products</option>
                      {products.map((p)=><option value={p} key={p}>{p}</option>)}
                    </Input>
                    <Input value={user} type="select" bsSize="sm"  style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'user')}>
                      <option>All Users</option>
                      {users.map((u)=><option value={u.id} key={u.id}>{u.name}</option>)}
                    </Input>
                  </div>
                  <Button
                    color="warning"
                    onClick={async ()=>{
                      this.toggleFilter();
                      await this.setState({user: 'All Users', product: 'All Products'})
                      await this.onFilter('All States', 'state');
                    }}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Hide Filters
                  </Button>
                </div>}
                <Table className={`align-items-center table-flush ${loading?'load-opacity':''}`} responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Customer</th>
                      <th scope="col">Product</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Date</th>
                      <th scope="col">User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sales.map((sale, key) => {
                      return (
                        <tr key={key} className="hover-list" to="/"
                          onClick={() => {console.log('111')
                            //this.props.history.push('/admin/users/1')
                          }}>
                          <td>{sale.name}</td>
                          <td>{sale.product_id}</td>
                          <td>{sale.quantity}</td>
                          <td>{moment(sale.saledate).format('MMM D, YYYY')}</td>
                          <td>{sale.user_name}</td>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&sales.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No sale found
                </div>}
                {sales.length>0&&<CardFooter className={`py-4 ${loading?'load-opacity':''}`}>
                  <CustomPagination page={page} total={total} rows={rows} onPage={this.onPage} />
                </CardFooter>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Sales;
