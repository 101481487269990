import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const targetService = {
    addTarget,
    getTargets,
    updateTarget,
    deleteTarget,
    exportTargets
};

export function getTargets(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}targets`, requestOptions).then(authService.handleResponse);
}

export function addTarget(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addtarget`, requestOptions).then(authService.handleResponse);
}

export function getTarget(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}target/${id}`, requestOptions).then(authService.handleResponse);
}

export function updateTarget(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatetarget/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteTarget(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletetarget/${id}`, requestOptions).then(authService.handleResponse);
}

export function exportTargets(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}exporttargets`, requestOptions).then(authService.handleResponse);
}
