/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Link } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import { toast } from 'react-toastify';
import { SecureRoute } from "../services/SecureRoute.js";
import { getUser, openSocket, messageSocket } from "../services/authService.js";
import {toPlay} from 'services/soundService'
import settings from "../services/settings.js";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import User from "views/users/User.jsx";
import Customer from "views/customers/Customer.jsx";
import Target from "views/targets/Target.jsx";
import Roles from "views/settings/roles/index.jsx";
import Products from "views/settings/products/index.jsx";
import Territories from "views/settings/territories/index.jsx";
import Axes from "views/settings/axes/index.jsx";
import Zones from "views/settings/zones/index.jsx";
import Teams from "views/settings/teams/index.jsx";
import TrackUser from "views/tracking/TrackUser.jsx";

import routes from "routes.js";

class Admin extends React.Component {
  state = {
    routes: []
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  componentDidMount(){
    if(getUser().admin){
      this.setState({routes});
    } else {
      this.setState({routes: routes.filter(r=>!r.admin)});
    }
    toast.configure({hideProgressBar: true, closeButton: false});
    this.openSocket()
  }

  openSocket(){
    openSocket();
		window.Echo.private(`user.${getUser().id}`)
			.listen('UserUpdate', e => {console.log(e)
        if (e.user&&getUser()) {
          e.user.token = getUser().token;
          localStorage.setItem('user', JSON.stringify(e.user));
        }
		  })
    let url = `chats.${getUser().id}`;
    messageSocket(this.props.history)
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <SecureRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.state.routes}
          logo={{
            innerLink: "/admin/home",
            imgSrc: require("assets/img/brand/repton.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            name={'user Name'}
          />
          <Switch>
            <Route exact path="/admin/tracking/:id" render={props => <TrackUser {...props} />} />
            <Route exact path="/admin/users/:id" render={props => <User {...props} />} />
            <Route exact path="/admin/targets/:id" render={props => <Target {...props} />} />
            <Route exact path="/admin/customers/:id" render={props => <Customer {...props} />} />
            <Route exact path="/admin/settings/roles" render={props => <Roles {...props} />} />
            <Route exact path="/admin/settings/products" render={props => <Products {...props} />} />
            <Route exact path="/admin/settings/territories" render={props => <Territories {...props} />} />
            <Route exact path="/admin/settings/axes" render={props => <Axes {...props} />} />
            <Route exact path="/admin/settings/zones" render={props => <Zones {...props} />} />
            <Route exact path="/admin/settings/teams" render={props => <Teams {...props} />} />
            {this.getRoutes(this.state.routes)}
          </Switch>
          {true&&false&&<Container fluid>
            <AdminFooter />
          </Container>}
        </div>
      </>
    );
  }
}

export default Admin;
