import React from "react";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  //CardFooter,
  //NavItem,
  //NavLink,
  //Nav,
  //Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2
} from "variables/charts.jsx";
import "views/index.css";

class Settings extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    settings: ['ROLES', 'PRODUCTS', 'TERRITORIES', 'AXES', 'ZONES', 'TEAMS'],
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidMount() {
  }

  render() {
    const {settings} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Settings </h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="success"
                        href="#p"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Edit
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                    {settings.map((s) => {
                      return (
                        <tr key={s} className="hover-list"
                          onClick={() => {
                            this.props.history.push(`/admin/settings/${s.toLowerCase()}`)
                          }}>
                          <th scope="row">{s}</th>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Settings;
