import settings from './settings';
import {authHeader} from './authHeader';
import { handleResponse } from './authService';

export default {
    getMessages,
    send,
};

function getMessages(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${settings.API_URL}messages/${data.userid}?page=${data.page}`, requestOptions).then(handleResponse);
}

async function send(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${settings.API_URL}send`, requestOptions).then(handleResponse);
}

export async function getUsers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}messageusers?page=${data.page}`, requestOptions).then(handleResponse);
}
