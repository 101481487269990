import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const territoryService = {
    addTerritory,
    getTerritories,
    updateTerritory,
    deleteTerritory
};

export function getTerritories(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}territories`, requestOptions).then(authService.handleResponse);
}

export function addTerritory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addterritory`, requestOptions).then(authService.handleResponse);
}

export function updateTerritory(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateterritory/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteTerritory(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteterritory/${id}`, requestOptions).then(authService.handleResponse);
}
