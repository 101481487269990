import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ReactDatetime from "react-datetime";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import moment from "moment";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { isMobile } from "react-device-detect";

import "views/index.css";
import { trackUser }  from 'services/userService';
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddTarget from "./AddTarget.jsx";

mapboxgl.accessToken = 'pk.eyJ1Ijoib2dib2xvZ2JvIiwiYSI6ImNqaWs1NjI2azF0NG4za243bTNqaTl2dnEifQ.TxMwvaXBL3cyVCT2NeyxHw';
//const map = mapboxgl.Map;
//const style = 'mapbox://styles/mapbox/streets-v9';

class TrackUser extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      activeNav: 1,
      loading: true,
    	page: 1,
    	rows: 7,
    	total: 0,
      search: '',
      states: [],
      axes: [],
      zones: [],
      users: [],
      products: [],
      state: 'All States',
      territory: 'All Territories',
      axis: 'All Axes',
      zone: 'All Zones',
      user: 'All Users',
      product: 'All Products',
      fromdate: moment().startOf('week').toDate(),
      todate: moment().endOf('week').toDate(),
      updates: [],
      lng: 8.639202253461804,
      lat: 9.081591917104092,
      zoom: 5.6724,
      layerIDs: [],
      image: require("assets/img/brand/user.jpg"),
      id: props.match.params.id,
      from: null,
      to: null
    };
	}

  componentDidMount() {
    this.trackUser();
    //this.initMap();
  }

  initMap() {
    const size = 70;
    var pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      onAdd: function() {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      render: function() {
        var duration = 1000;
        var t = (performance.now() % duration) / duration;

        var radius = (size / 2) * 0.3;
        var outerRadius = (size / 2) * 0.7 * t + radius;
        var context = this.context;

        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 200, 200,' + (1 - t) + ')';
        context.fill();

        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          radius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        map.triggerRepaint();
        return true;
      }
    };
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom
    });
    this.map = map;

    this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    this.map.on('load', (event) => {
      this.map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
      this.map.addSource('Tracking',
      {
        "type": "geojson",
        "data": this.state.ft
      });

      this.state.ft.features.forEach((feature) => {
          var symbol = feature.properties['icon'];
          var layerID = feature.properties['user'].id.toString();
          var user = feature.properties['user'];

          // Add a layer for this symbol type if it hasn't been added already.
          if (!this.map.getLayer(layerID)) {
              this.map.addLayer({
                  "id": layerID,
                  "type": "symbol",
                  "source": "Tracking",
                  "layout": {
                      "icon-image": "pulsing-dot",
                      "icon-allow-overlap": true,
                      "text-field": moment(user.timestamp).format('MMM D, YYYY HH:mm'),
                      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                      "text-size": 12,
                      //"text-transform": "uppercase",
                      "text-letter-spacing": 0.05,
                      "text-offset": [0, 1.5]
                  },
                  "paint": {
                      "text-color": "#EC3237",
                      "text-halo-color": "#fff",
                      "text-halo-width": 1,
                      "icon-color": "#EC3237",
                  },
                  "filter": ["==", "userid", layerID]
              });

              //this.layerIDs.push(layerID);
              this.setState({layerIDs: this.state.layerIDs.concat(layerID)})

              this.popup = new mapboxgl.Popup({
                  closeButton: false,
                  closeOnClick: false
              });

              this.map.on('mouseenter', layerID, (e) => {
                  this.map.getCanvas().style.cursor = 'pointer';

                  var coordinates = e.features[0].geometry.coordinates.slice();
                  var description = e.features[0].properties.description;

                  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                  }

                  if(this.selected==''){
                    }
                      this.popup.setLngLat(coordinates)
                        .setHTML(description)
                        .addTo(this.map);
              });

              this.map.on('mouseleave', layerID,  () => {
                  this.map.getCanvas().style.cursor = '';
                  this.popup.remove();
              });
            }
        });
    });

    this.map.on('move', () => {
      this.setState({
        lng: this.map.getCenter().lng.toFixed(4),
        lat: this.map.getCenter().lat.toFixed(4),
        zoom: this.map.getZoom().toFixed(2)
      });
    });
  }

  trackUser = (paging) => {
    const {id, page, rows, search, fromdate, todate, updates} = this.state;
    this.setState({loading: true});
    trackUser({id, fromdate, todate, page})
      .then(async (res) => {console.log(res)
        await this.setState({
          updates: res.updates.data,
          page: res.updates.current_page,
          total: res.updates.total,
          from: res.updates.from,
          to: res.updates.to,
          user: res.user,
          loading: false
        })
        await this.setFt();
        if(!paging){
          await this.initMap();
        } else {
          await this.remove(updates.map(u=>u.id));
          await this.useFt();
        }
        await this.add(res.updates.data);
        //await setTimeout(()=>this.add(res.updates.data), paging?0:4000);
      },
      error => {
        this.setState({loading: false});
      });
  }

  setFt = () => {
    const {updates, image} = this.state;
    const ft = {
        "type": "FeatureCollection",
        "features": updates.map(f => ({
          "type": "Feature",
          "properties": {
              "description":
                "<p class='map-pop'>"+moment(f.timestamp).calendar(null, {sameElse: 'MMM D, YYYY HH:mm'})+"</p>",
              "icon": "circle",
              "user": f,
              "userid": f.id.toString(),
          },
          "geometry": {
              "type": "Point",
              "coordinates": [f.longitude, f.latitude]
          }
        }))
    }
    this.setState({ft});
  }

  useFt = () => {
    this.map.removeSource('Tracking');
    this.map.addSource('Tracking',
    {
      "type": "geojson",
      "data": this.state.ft
    });
    this.state.ft.features.forEach((feature) => {
        var symbol = feature.properties['icon'];
        var layerID = feature.properties['user'].id.toString();
        var user = feature.properties['user'];

        // Add a layer for this symbol type if it hasn't been added already.
        if (!this.map.getLayer(layerID)) {
            this.map.addLayer({
                "id": layerID,
                "type": "symbol",
                "source": "Tracking",
                "layout": {
                    "icon-image": "pulsing-dot",
                    "icon-allow-overlap": true,
                    "text-field": moment(user.timestamp).format('MMM D, YYYY HH:mm'),
                    "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                    "text-size": 12,
                    //"text-transform": "uppercase",
                    "text-letter-spacing": 0.05,
                    "text-offset": [0, 1.5]
                },
                "paint": {
                    "text-color": "#EC3237",
                    "text-halo-color": "#fff",
                    "text-halo-width": 1,
                    "icon-color": "#EC3237",
                },
                "filter": ["==", "userid", layerID]
            });

            //this.layerIDs.push(layerID);
            this.setState({layerIDs: this.state.layerIDs.concat(layerID)})

            this.popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            this.map.on('mouseenter', layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';

                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;

                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                if(this.selected==''){
                  }
                    this.popup.setLngLat(coordinates)
                      .setHTML(description)
                      .addTo(this.map);
            });

            this.map.on('mouseleave', layerID,  () => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
          }
      });
  }

  onFilter = async (e, state) => {
    await this.setState({[state]: e})
    await this.trackUser();
  }

  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }

  onPage = async (page) => {
    const {search} = this.state;
    await this.setState({page});
    await this.trackUser(true);
  }

  hoverOn = (e) => {
    const {image} = this.state;
    if(this.popup){
      this.popup.setLngLat([e.longitude, e.latitude])
        .setHTML(
          "<p class='map-pop'>"+moment(e.timestamp).calendar(null, {sameElse: 'MMM D, YYYY HH:mm'})+"</p>")
        .addTo(this.map);
    }
  }

  hoverOff = () => {
    if(this.popup){
      this.popup.remove();
    }
  }

  setLayout = async (upd) => {
    if(upd.length>1){
      const line = turf.lineString(upd)
      const bbox = turf.bbox(line)
      await setTimeout(()=>this.map.fitBounds(bbox, {
        padding: {top: 40, bottom:40, left: 40, right: isMobile?40:340}
      }), 2000)
    } else if(upd.length===1) {
      await setTimeout(()=>this.map.flyTo({
          center: upd[0],
          zoom: 9,
          pitch: 0,
          bearing: 0
      }), 2000)
    }
  }

  remove = (ids) => {
    ids.forEach((layerID) => {
      this.map.setLayoutProperty(layerID, 'visibility', 'none');
      if (this.map.getLayer(layerID)) this.map.removeLayer(layerID);
    });
  }

  add = async (updates) => {
    this.map.on('styledata', async (e) => {console.log(this.map.isStyleLoaded())
      if(this.map.isStyleLoaded()){
        for (const layerID of updates.map(u=>u.id)) {
          await this.map.setLayoutProperty(layerID, 'visibility', 'visible');
        }
        await this.setLayout(updates.map(u=>[u.longitude, u.latitude]));
      }
    });
  }

  render() {
    const {targets, showFilter, addTarget, total, page, rows, search, loading, state, territory,
      axis, zone, product, user, states, axes, zones, products, users, fromdate, todate, updates, from, to} = this.state;
    //this.map&&console.log(this.map.isStyleLoaded())
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <div>
            <div ref={el => this.mapContainer = el} className='mapContainer' />
          </div>
          <Row className="hide-mobile" style={{position: 'absolute', right: 10}}>
            <Col className="mb-0 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div>
                      {!loading&&<Link
                        to="/admin/tracking"
                        style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#EC3237', right: 15, top: 10, fontSize: 14}}>
                        <span>Back</span>
                      </Link>}
                      <p style={{margin:0, fontSize:15, fontWeight: 'bold'}}>{user.name}</p>
                      <p style={{marginBottom:5, fontSize:13, fontWeight: '500'}}>{user.role_id}</p>
                      <div className="mobile-flex" style={{alignItems: 'center'}}>
      <DatePicker
        popperPlacement="bottom-start"
        popperModifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false
          }
        }}
        dateFormat="MMM d, yyyy"
        selected={fromdate}
        onChange={e=>this.onFilter(e, 'fromdate')}
        selectsStart
        startDate={fromdate}
        endDate={todate}
        maxDate={todate}
        style={{border:1}}
      />
      <span className="hide-mobile" style={{padding: '0 10px'}}>-</span>
      <DatePicker
        popperPlacement="bottom-end"
        popperModifiers={{
          flip: {
            enabled: false
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false
          }
        }}
        dateFormat="MMM d, yyyy"
        selected={todate}
        onChange={e=>this.onFilter(e, 'todate')}
        selectsEnd
        startDate={fromdate}
        endDate={todate}
        minDate={fromdate}
      />
                        {false&&<ReactDatetime
                          className="date-input"
                          value={fromdate}
                          dateFormat={'MMM D, YYYY'}
                          closeOnSelect
                          onChange={e=>this.onFilter(e, 'fromdate')}
                          inputProps={{
                            required: true,
                            className: 'form-control date-filter date-input'
                          }}
                          isValidDate={(current)=>{return (current.isBefore(todate)||current.isSame(todate))&&current.isBefore(moment());}}
                          timeFormat={false}
                        />}
                        {false&&<ReactDatetime
                          className="date-input"
                          value={todate}
                          dateFormat={'MMM D, YYYY'}
                          closeOnSelect
                          onChange={e=>this.onFilter(e, 'todate')}
                          inputProps={{
                            required: true,
                            className: 'form-control date-filter date-input'
                          }}
                          isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                          timeFormat={false}
                        />}
                      </div>
                    </div>
                  </Row>
                  <div style={{paddingTop: 10}}>
                    {updates.map((u)=>(
                      <div key={u.id} className="map-list"
                        onMouseEnter={() => this.hoverOn(u)}
                        onMouseLeave={() => this.hoverOff(u)}>
                        <p style={{fontSize: 14, fontWeight: '600', display: 'flex', alignItems: 'center'}}>
                          <i className=" ni ni-pin-3" style={{marginRight: 5}} />
                          {moment(u.timestamp).format('MMM D, YYYY HH:mm')}
                        </p>
                      </div>
                    ))}
                    {!loading&&updates.length<1&&
                    <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                      <i className="fa fa-ban" style={{marginRight: 5}}/>
                      No user found
                    </div>}
                    {total>rows&&<div style={{marginTop: 20}}>
                      <CustomPagination range={`${from}-${to}`} page={page} total={total} rows={rows} onPage={this.onPage} />
                    </div>}
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default TrackUser;
