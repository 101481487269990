export default {
    onPlay
};

export function onPlay() {
  const audio = new Audio('http://192.168.43.110:8555/micro.mp3');
  audio.play();
}

export function toPlay(s){
  const audio = new Audio(s);
  audio.load();
  const audioPromise = audio.play();
  if (audioPromise !== undefined) {
    audioPromise
      .then(() => {
        // autoplay started
      })
      .catch(err => {
        // catch dom exception
        console.info(err)
      })
  }
}
