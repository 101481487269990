import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from "moment";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import * as mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { isMobile } from "react-device-detect";

import "views/index.css";
import { getTracking }  from 'services/userService';
import {openSocket} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import CustomPagination from "components/CustomPagination.jsx";
import AddTarget from "./AddTarget.jsx";

mapboxgl.accessToken = 'pk.eyJ1Ijoib2dib2xvZ2JvIiwiYSI6ImNqaWs1NjI2azF0NG4za243bTNqaTl2dnEifQ.TxMwvaXBL3cyVCT2NeyxHw';
//const map = mapboxgl.Map;
//const style = 'mapbox://styles/mapbox/streets-v9';

class Tracking extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 5,
  	total: 0,
    search: '',
    states: [],
    axes: [],
    zones: [],
    users: [],
    products: [],
    state: 'All States',
    territory: 'All Territories',
    axis: 'All Axes',
    zone: 'All Zones',
    user: 'All Users',
    product: 'All Products',
    fromdate: moment().startOf('month'),
    todate: moment().endOf('month'),
    updates: [],
    lng: 8.639202253461804,
    lat: 9.081591917104092,
    zoom: 5.6724,
    layerIDs: [],
    image: require("assets/img/brand/user.jpg")
  };

  componentDidMount() {
    this.getTracking();
    //this.initMap();
    this.listen();
  }

  listen = () => {
    if(!window.Echo){
      openSocket();
    }
    window.Echo.private('updates')
      .listen('NewUpdate', e => {console.log(e)
        //if(e.message.sender_id!==getUser().id){
        //  this.updateMessages(e.message);
        //  if(this.messagesEnd){
        //    setTimeout(() => this.scrollToBottom(), 100);
        //  }
        //  toPlay('/receive.mp3');
        //}
      })
  }

  initMap() {
    const size = 70;
    var pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      onAdd: function() {
        var canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      render: function() {
        var duration = 1000;
        var t = (performance.now() % duration) / duration;

        var radius = (size / 2) * 0.3;
        var outerRadius = (size / 2) * 0.7 * t + radius;
        var context = this.context;

        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 200, 200,' + (1 - t) + ')';
        context.fill();

        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          radius,
          0,
          Math.PI * 2
        );
        context.fillStyle = 'rgba(255, 100, 100, 1)';
        context.strokeStyle = 'white';
        context.lineWidth = 2 + 4 * (1 - t);
        context.fill();
        context.stroke();

        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        map.triggerRepaint();
        return true;
      }
    };
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/outdoors-v11',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom
    });
    this.map = map;

    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

    map.on('load', (event) => {
      map.addImage('pulsing-dot', pulsingDot, { pixelRatio: 2 });
      map.addSource('Tracking',
      {
        "type": "geojson",
        "data": this.state.ft
      });

      this.state.ft.features.forEach((feature) => {
          var symbol = feature.properties['icon'];
          var layerID = feature.properties['user'].id.toString();
          var user = feature.properties['user'];

          // Add a layer for this symbol type if it hasn't been added already.
          if (!map.getLayer(layerID)) {
              map.addLayer({
                  "id": layerID,
                  "type": "symbol",
                  "source": "Tracking",
                  "layout": {
                      "icon-image": "pulsing-dot",
                      "icon-allow-overlap": true,
                      "text-field": user.name,
                      "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                      "text-size": 12,
                      //"text-transform": "uppercase",
                      "text-letter-spacing": 0.05,
                      "text-offset": [0, 1.5]
                  },
                  "paint": {
                      "text-color": "#EC3237",
                      "text-halo-color": "#fff",
                      "text-halo-width": 1,
                      "icon-color": "#EC3237",
                  },
                  "filter": ["==", "userid", layerID]
              });

              //this.layerIDs.push(layerID);
              this.setState({layerIDs: this.state.layerIDs.concat(layerID)})

              this.popup = new mapboxgl.Popup({
                  closeButton: false,
                  closeOnClick: false
              });

              map.on('mouseenter', layerID, (e) => {
                  map.getCanvas().style.cursor = 'pointer';

                  var coordinates = e.features[0].geometry.coordinates.slice();
                  var description = e.features[0].properties.description;

                  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                  }

                  if(this.selected==''){
                    }
                      this.popup.setLngLat(coordinates)
                        .setHTML(description)
                        .addTo(map);
              });

              map.on('mouseleave', layerID,  () => {
                  map.getCanvas().style.cursor = '';
                  this.popup.remove();
              });
            }
        });
    });

    map.on('move', () => {
      this.setState({
        lng: map.getCenter().lng.toFixed(4),
        lat: map.getCenter().lat.toFixed(4),
        zoom: map.getZoom().toFixed(2)
      });
    });
  }

  getTracking = () => {
    const {state, territory, axis, zone, product, user, page, rows, search, fromdate, todate} = this.state;
    this.setState({loading: true});
    getTracking({state, territory, axis, zone, page, rows, search, fromdate, todate})
      .then(async (res) => {console.log(res)
        await this.setState({
          updates: res.updates,
          users: res.users.data,
          page: res.users.current_page,
          total: res.users.total,
          loading: false
        })
        await this.setFt();
        await this.initMap();
        const upd = this.state.updates.map(u=>[u.longitude, u.latitude])
        if(upd.length>1){
          const line = turf.lineString(upd)
          const bbox = turf.bbox(line)
          await setTimeout(()=>this.map.fitBounds(bbox, {
            padding: {top: 40, bottom:40, left: 40, right: isMobile?40:340}
          }), 2000)
        } else if(upd.length===1) {
          await setTimeout(()=>this.map.flyTo({
              center: upd[0],
              zoom: 9,
              pitch: 0,
              bearing: 0
          }), 2000)
        }
      },
      error => {
        this.setState({loading: false});
      });
  }

  search = () => {
    const {user, page, rows, search} = this.state;
    this.setState({loading: true});
    getTracking({page, rows, search})
      .then(async (res) => {console.log(res)
        await this.setState({
          updates: res.updates,
          users: res.users.data,
          page: res.users.current_page,
          total: res.users.total,
          loading: false
        })
        const userids = this.state.updates.map(u=>u.id.toString())
        await this.filterLayer(userids);
        //await this.initMap();
        const upd = this.state.updates.map(u=>[u.longitude, u.latitude])
        if(upd.length>1){
          const line = turf.lineString(upd)
          const bbox = turf.bbox(line)
          await setTimeout(()=>this.map.fitBounds(bbox, {
            padding: {top: 40, bottom:40, left: 40, right: isMobile?40:340}
          }), 2000)
        } else if(upd.length===1) {
          await setTimeout(()=>this.map.flyTo({
              center: upd[0],
              zoom: 9,
              pitch: 0,
              bearing: 0
          }), 2000)
        }
      },
      error => {
        this.setState({loading: false});
      });
  }

  setFt = () => {
    const {updates, image} = this.state;
    const ft = {
        "type": "FeatureCollection",
        "features": updates.map(f => ({
          "type": "Feature",
          "properties": {
              "description": "<div>"+
                "<img "+
                "src="+image+
                " width='0px'>"+
                "</div>"+
                "<p class='map-pop'><span style='font-weight:normal'>Name: </span>"+f.name+"</p>"+
                "<p class='map-pop'><span style='font-weight:normal'>Role: </span>"+(f.role_id?f.role_id:'N/A')+"</p>"+
                "<p class='map-pop'><span style='font-weight:normal'>Last seen: </span>"+moment(Number(f.timestamp)).calendar(null, {sameElse: 'MMM D, YYYY HH:mm'})+"</p>",
              "icon": "circle",
              "user": f,
              "userid": f.id.toString(),
          },
          "geometry": {
              "type": "Point",
              "coordinates": [f.longitude, f.latitude]
          }
        }))
    }
    this.setState({ft});
  }

  filterLayer = (userids) => {
    const {layerIDs} = this.state;
    console.log(layerIDs, userids);
    layerIDs.forEach((layerID) => {
      this.map.setLayoutProperty(layerID, 'visibility',
          userids.includes(layerID) ? 'visible' : 'none');
          console.log(userids.includes(layerID));
    });
    console.log(layerIDs);
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  showToast = msg => {
    toast.error(<div style={{padding:25}}>{msg}</div>);
  }

  onPage = async (page) => {
    const {search} = this.state;
    await this.setState({page});
    if(search==''){
      await this.getTracking();
    } else {
      await this.search();
    }
  }

  hoverOn = (e) => {
    const {image} = this.state;
    if(this.popup){
      this.popup.setLngLat([e.longitude, e.latitude])
        .setHTML(
          /*"<div>"+
          "<img "+
          "src="+image+
          " width='0px'>"+
          "</div>"+*/
          "<p class='map-pop'><span style='font-weight:normal'></span>"+e.name+"</p>"+
          "<p class='map-pop'><span style='font-weight:normal'></span>"+(e.role_id?e.role_id:'N/A')+"</p>"+
          "<p class='map-pop'><span style='font-weight:normal'>Last seen: </span>"+moment(Number(e.timestamp)).calendar(null, {sameElse: 'MMM D, YYYY HH:mm'})+"</p>")
        .addTo(this.map);
    }
  }

  hoverOff = () => {
    if(this.popup){
      this.popup.remove();
    }
  }

  render() {
    const {targets, showFilter, addTarget, total, page, rows, search, loading, state, territory,
      axis, zone, product, user, states, axes, zones, products, users, fromdate, todate, updates} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <div>
            <div ref={el => this.mapContainer = el} className='mapContainer' />
          </div>
          <Row className="hide-mobile" style={{position: 'absolute', right: 10}}>
            <Col className="mb-0 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv />}
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap'}}>
                    <div className="mobile-flex">
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.search();}}} />
                      <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.search}>
                        <i className="fa fa-search" />
                      </Button>
                    </div>
                  </Row>
                  <div style={{paddingTop: 20}}>
                    {users.map((u)=>(
                      <div key={u.id} className="map-list"
                        onClick={()=>this.props.history.push('/admin/tracking/'+u.id)}
                        onMouseEnter={() => this.hoverOn(u)}
                        onMouseLeave={() => this.hoverOff(u)}>
                        <p style={{fontSize: 14, fontWeight: '600'}}>{u.name}</p>
                        <p>{u.role_id}</p>
                        <p>Last seen {moment(Number(u.timestamp)).format('MMM D, YYYY HH:mm')}</p>
                      </div>
                    ))}
                    {!loading&&users.length<1&&
                    <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                      <i className="fa fa-ban" style={{marginRight: 5}}/>
                      No user found
                    </div>}
                    {total>5&&<div style={{marginTop: 20}}>
                      <CustomPagination page={page} total={total} rows={rows} onPage={this.onPage} />
                    </div>}
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Tracking;
