import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";

import "views/index.css";
import { getRoles }  from 'services/roleService';
import AddRole from "./AddRole.jsx";
import EditRole from "./EditRole.jsx";
import DeleteRole from "./DeleteRole.jsx";
import SpinDiv from "components/SpinDiv.jsx";
import "views/index.css";

class Roles extends React.Component {
  state = {
    roles: [],
    page: 1,
  	total: 0,
    search: '',
    role: 'All Roles',
    loading: true,
    validation: []
   };

  componentDidMount() {
    this.getRoles();
  }

  getRoles = () => {
    const { page, search } = this.state;
    this.setState({loading: true});
    getRoles({page, search})
      .then((res) => {
        this.setState({
          roles: res.roles.data,
          page: res.roles.current_page,
          total: res.roles.total,
          loading: false
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleAdd = () => {
    this.setState({add: !this.state.add});
  }

  toggleEdit = (editRole) => {
    this.setState({editRole});
  }

  toggleDelete = (deleteRole) => {
    this.setState({deleteRole});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  render() {
    const {add, loading, showFilter, search, role, roles, editRole, deleteRole} = this.state;

      return (
        <>
          <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
            <Container fluid>
              <div className="header-body">
                <Link
                  to="/admin/settings"
                  className="white-link"
                  style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                  <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                  <span>Back to Settings</span>
                </Link>
              </div>
            </Container>
          </div>
          {/* Page content */}
          {add&&
            <AddRole
              saved={this.getRoles}
              toggle={this.toggleAdd}
              add={add}
              role={role}
            />}
          {editRole&&
            <EditRole
              saved={this.getRoles}
              editRole={editRole}
              role={role}
              toggle={()=>this.setState({editRole: null})}
            />}
          {deleteRole&&
            <DeleteRole
              saved={this.getRole}
              deleteRole={deleteRole}
              role={role}
              toggle={()=>this.setState({deleteRole: null})}
            />}
          <Container className="mt--7" fluid>
            <Row>
              <Col className="mb-5 mb-xl-0" xl="12">
                <Card className="shadow">
                  {loading&&<SpinDiv text={'Loading...'} />}
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Roles </h3>
                      </div>
                      <div style={{display: 'flex'}}>
                        <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5}}
                          value={search}
                          onChange={e=>this.onChange(e.target.value, 'search')}
                          onKeyUp={(e) => {if(e.key==='Enter'){this.getRoles();}}} />
                        <Button className="btn-icon btn-2" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getRoles}>
                          <i className="fa fa-search" />
                        </Button>
                        <Button
                          color="success"
                          onClick={this.toggleAdd}
                          size="sm"
                        >
                          Add Role
                        </Button>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Number of Users</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {roles.map((role) => {
                        return (
                          <tr key={role.id} className="hover-list">
                            <th scope="row">{role.name}</th>
                            <td>{role.users_count}</td>
                            <td>
                              <Button
                                color="secondary"
                                onClick={() => this.toggleEdit(role)}
                                size="sm"
                              >
                                Edit
                              </Button>
                              <Button
                                color="danger"
                                onClick={() => this.toggleDelete(role)}
                                size="sm"
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                  {!loading&&roles.length<1&&
                  <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                    <i className="fa fa-ban" style={{marginRight: 5}}/>
                    No role found
                  </div>}
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }

  export default Roles;
