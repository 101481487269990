export default {
	//API_URL: 'http://185.52.1.113:5005/api/',
	//FILE_URL: 'http://185.52.1.113:5005/storage/',
	API_URL: 'https://api.repton.com.ng/api/',
	FILE_URL: 'https://api.repton.com.ng/storage/',
	//API_URL: 'https://demoapi.repton.com.ng/api/',
	//FILE_URL: 'https://demoapi.repton.com.ng/storage/',
	//API_URL: 'https://demoapi.supermpro.com/api/',
  WS_URL: 'https://api.repton.com.ng/broadcasting/auth',
	VERSION: 1
};
