import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";
import { getZones }  from 'services/zoneService';
import AddZone from "./AddZone.jsx";
import EditZone from "./EditZone.jsx";
import DeleteZone from "./DeleteZone.jsx";
import SpinDiv from "components/SpinDiv.jsx";
import "views/index.css";
import { filter }  from 'services/authService';


class Zones extends React.Component {
  state = {
    zones: [],
    states: [],
  	page: 1,
  	total: 0,
    search: '',
    territories: [],
    axes: [],
    state: 'All States',
    territory: 'All Territories',
    axis: 'All Axes',
  };

  componentDidMount() {
    this.getZones();
  }

  getZones = () => {
    const {state, territory, axis, page, search} = this.state;
    this.setState({loading: true});
    getZones({state, territory, axis, page, search})
      .then((res) => {
        this.setState({
          zones: res.zones.data,
          states: res.states,
          page: res.zones.current_page,
          total: res.zones.total,
          loading: false,
          territories: res.territories,
          axes: res.axes
        })
      },
      error => {console.log(error)
        this.setState({loading: false});
      });
  }

  toggleAdd = () => {
    this.setState({add: !this.state.add});
  }

  toggleEdit = (editZone) => {
    this.setState({editZone});
  }

  toggleDelete = (deleteZone) => {
    this.setState({deleteZone});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          console.log(res)
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  onFilter = async (e, state) => {
    await this.setState({[state]: e});
    await this.getZones();
  }

  render() {
    const {zones, add, states, loading, showFilter, search, state, territory, territories,
      axis, axes, filtering, editZone, deleteZone} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/settings"
                className="white-link"
                style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                <span>Back to Settings</span>
              </Link>
            </div>
          </Container>
        </div>
        {/* Page content */}
        {add&&
          <AddZone
            saved={this.getZones}
            toggle={this.toggleAdd}
            states={states}
            add={add}
          />}
        {editZone&&
          <EditZone
            saved={this.getZones}
            states={states}
            editZone={editZone}
            toggle={()=>this.setState({editZone: null})}
          />}
        {deleteZone&&
          <DeleteZone
            saved={this.getZones}
            states={states}
            deleteZone={deleteZone}
            toggle={()=>this.setState({deleteZone: null})}
          />}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Zones </h3>
                    </div>
                    <div style={{display: 'flex'}}>
                      {!showFilter&&<Button
                        color="warning"
                        onClick={this.toggleFilter}
                        size="sm"
                        style={{marginRight: 10}}
                      >
                        Filter
                      </Button>}
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getZones();}}} />
                      <Button className="btn-icon btn-2" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getZones}>
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        color="success"
                        onClick={this.toggleAdd}
                        size="sm"
                      >
                        Add Zone
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {showFilter&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Filter Zones: </span>
                    <Input value={state} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'state')}>
                      <option>All States</option>
                      {states.map((s)=><option value={s} key={s}>{s}</option>)}
                    </Input>
                    {state!=='All States'&&
                    <Input value={territory} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'territory')}>
                      <option>All Territories</option>
                      {territories.map((t)=><option value={t} key={t}>{t}</option>)}
                    </Input>}
                    {territory!=='All Territories'&&
                    <Input value={axis} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}} onChange={e=>this.onFilter(e.target.value, 'axis')}>
                      <option>All Axes</option>
                      {axes.map((a)=><option value={a} key={a}>{a}</option>)}
                    </Input>}
                  </div>
                  <Button
                    color="warning"
                    onClick={this.toggleFilter}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Hide Filters
                  </Button>
                </div>}
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Axis</th>
                      <th scope="col">Territory</th>
                      <th scope="col">State</th>
                      <th scope="col">No. of Customers</th>
                      <th scope="col">No. of Users</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {zones.map((zone) => {
                      return (
                        <tr key={zone.id} className="hover-list">
                          <th scope="row">{zone.name}</th>
                          <td>{zone.axis_id}</td>
                          <td>{zone.territory_id}</td>
                          <td>{zone.state_id}</td>
                          <td>{zone.customers_count}</td>
                          <td>{zone.users_count}</td>
                          <td>
                            <Button
                              color="secondary"
                              onClick={() => this.toggleEdit(zone)}
                              size="sm"
                            >
                              Edit
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(zone)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&zones.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No zone found
                </div>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Zones;
