import React from 'react';
//import { Input, Tooltip, Icon, Button, Spin } from 'antd';
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import moment from 'moment';
// import Echo from 'laravel-echo';
import {toPlay} from 'services/soundService'
import messageService from 'services/messageService'
// import options from 'services/echo'
import {getUser, openSocket} from 'services/authService'
import SpinDiv from "components/SpinDiv.jsx";
import style from './style.module.scss'
// const { TextArea } = Input;
// const echo = new Echo(options);

const Message = ({ chat, user }) => (
  <div className={`${style.message} ${user === chat.sender_name ? style.me : style.companion}`}>
    <div className={style.messageContent}>
      <strong>{chat.sender_name}</strong>
      <p className={style.textContent}>{chat.content}</p>
      <span className={style.time}>
        {moment(chat.sent).calendar(null, {sameElse: 'MMM D, YYYY HH:mm'})}
        {user===chat.sender_name&&
          <i style={{color: '#bbb', fontSize: 11, position: 'absolute', right: 7, bottom: 7}} className="fa fa-check" />
        }
      </span>
    </div>
  </div>
)

class Broadcast extends React.Component {
  state = {
    username: getUser().name,
    messages: [],
    newchats: [],
    rows: 20,
    page: 1,
    text: ''
  };

  componentDidMount() {
    //this.getMessages();
    //this.listen();
  }

  componentDidUpdate() {
    // this.scrollToBottom();
  }

  listen = () => {
    const { receiver } = this.props
    let url
    if(getUser().id>receiver.id){
      url = `chat.${receiver.id}.${getUser().id}`;
    } else {
      url = `chat.${getUser().id}.${receiver.id}`;
    }
    // window.Echo.connector.options.auth.headers['X-Socket-Id'] = window.Echo.socketId();
    if(!window.Echo){
      openSocket();
    }
    window.Echo.private(url)
      .listen('NewMessage', e => {console.log(e)
        if(e.message.sender_id!==getUser().id){
          this.updateMessages(e.message);
          if(this.messagesEnd){
            setTimeout(() => this.scrollToBottom(), 100);
          }
          toPlay('/receive.mp3');
        }
      })
  }

  updateMessages = (message) => {
    const { messages } = this.state
    this.setState({messages: messages.concat([message])});
  }

  getMessages = () => {
    const { rows, page } = this.state
    const { receiver } = this.props
    this.setState({loading: true});
    messageService.getMessages({rows, page, userid: receiver.id})
      .then(
          e => {console.log(e)
              this.setState({
                messages: e.messages.data.reverse(),
                page: e.messages.current_page,
                total: e.messages.total,
                loading: false
              });
              setTimeout(() => this.scrollToBottom(), 100);
          },
          err => console.log(err)
      );
  }

  sendMessage = (timestamp) => {
    const { text, messages } = this.state
    const { receiver } = this.props
    messageService.send({
      content: text,
      support: getUser().supe,
      timestamp,
      receiverid: receiver.id
    })
      .then(
          e => {console.log(e)
            const newmessages = messages.map(m => {
              return m.timestamp.toString()===timestamp.toString()? e.message:m;
            })
            console.log(newmessages)
            // this.setState({nmessages: newmessages})
          },
          err => console.log(err)
      );
  }

  send = e => {
    const { username, messages, text } = this.state
    e.preventDefault();
    if(text!==''&&(text.replace(/\s/g, '').length>0)){
      const timestamp = moment().valueOf()
      const c = {
        sender_name: username,
        img: "/resources/images/avatars/user.jpg",
        content: text,
        timestamp
      };

      this.setState({messages: messages.concat([c]), text: ''});
      setTimeout(() => this.scrollToBottom(), 100);
      this.sendMessage(timestamp);
      toPlay('/send.mp3');
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  render() {
    const { username, text, messages, loading, total, newchats } = this.state
    const { height, receiver, close } = this.props
    
    return (
      <div style={{width: '100%'}}>
        {loading?
          <div style={{textAlign: 'center', padding: 30}}>
            <SpinDiv text={'Loading messages...'} />
          </div>:
          <div className={style.chat} style={{paddingBottom:15, borderRadius: 10, boxShadow: '0px 0px 0px 1px rgba(0,0,0,0.1)'}}>
            <div style={{padding: '8px 10px 8px 15px', display: 'flex', boxShadow: '0px 1px 0px 0px rgba(0,0,0,0.1)', backgroundColor: '#EC3237'}}>
              <div style={{marginLeft: 7}}>
                <p style={{fontSize: 12, fontWeight: '600', margin: 0, color: '#fff'}}>Broadcast</p>
                <p style={{margin: 0, fontSize: 11, lineHeight: 1, color: '#fff'}}>Send message to multiple Users</p>
              </div>
              <i className="fa fa-times" style={{position: 'absolute', right:35, top: 35, color: '#fff'}} onClick={close} />
            </div>
            <div className={style.messaging} style={{ height, paddingLeft: 15, paddingRight: 10 }}>
              <div>
                {messages.concat(newchats).map(chat => (
                  <Message chat={chat} user={username} key={Math.random()} />
                ))}
              </div>
              <div style={{ float:"left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }} />
            </div>
            <form className="form-group mt-4 mb-0 ml-4" onSubmit={this.send}>
              <div style={{display: 'flex'}}>
                <Input
                  autoFocus
                  bsSize="sm"
                  onChange={e => this.setState({text: e.target.value})}
                  // className="form-control adjustable-textarea"
                  placeholder="Type message"
                  value={text}
                  // suffix={
                  //   <Tooltip title="Attach File">
                  //     <Icon type="paper-clip" style={{ color: 'rgba(0,0,0,.45)', cursor: 'pointer', fontSize: 16 }} />
                  //   </Tooltip>
                  // }
                />
                <div>
                  <Button className="btn-icon btn-2" color="danger" size="sm" style={{marginLeft: 10, width: 32, marginRight: 15}} onClick={this.send}>
                    <i className="fa fa-paper-plane" />
                  </Button>
                </div>
              </div>
            </form>
          </div>}
      </div>
    )
  }
}

export default Broadcast
