/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  //NavItem,
  //NavLink,
  //Nav,
  //Progress,
  //Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2
} from "variables/charts.jsx";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    slides: [
      {type: 'text', content: 'Some informative text'},
      {type: 'image', src: 'Some informative text'},
      {type: 'video', src: 'Some informative text'},
      {type: 'audio', src: 'Some informative text'},
      {type: 'text', content: 'Some informative text'}
    ],
    questions: [
      {content: '2 + 2?', type: 'single',
        answers: [
          {value: '5', id: 1},
          {value: '4', id: 2},
          {value: '3', id: 3}
        ]
      },
      {content: '2 + 5?', type: 'multiple',
        answers: [
          {value: '5', id: 1},
          {value: '5 + 2', id: 2},
          {value: '7', id: 3},
          {value: '3', id: 4}
        ]
      },
      {content: '10 + 5 = 105?', type: 'boolean',
        answers: [
          {value: 'True', id: 1},
          {value: 'False', id: 2}
        ]
      }
    ],
    start: false,
    slide: 0,
    status: '',
    question: 0
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  onStart(){
    this.setState({status: 'slide'});
  }

  onFinish(){
    this.setState({status: 'question'});
  }

  onSubmit(){
    this.setState({status: 'done'});
    window.close();
  }

  render() {
    const {status} = this.state;
    return (
      <>
        <div className="header bg-gradient-success pb-8 pt-1">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              {status===''&&<Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col text-center">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        <span>Created 25th May, 2019</span>
                      </h6>
                      <h2 className="mb-0" style={{color: '#8b0000', cursor: 'pointer'}}>
                        Module Title4
                      </h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div style={{height: 300}}>
                    <p className="text-center">
                      A not too short and not too long description of this Module can be inserted right here
                    </p>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                      <p className="text-center" style={{marginBottom: 5}}>
                        You will be required to answer a few questions at the end of this module.
                      </p>
                      <p className="text-center">
                        This module will only be marked as COMPLETE if you answer at least 90% of the questions correctly.
                      </p>
                    </div>
                    <p className="text-center" style={{color: 'red', cursor: 'pointer'}}>
                      Do NOT close or refresh your browser after starting this course.
                    </p>
                  </div>
                  <h4 className="text-center mb-0" style={{color: '#8b0000', cursor: 'pointer'}}
                    onClick={() => this.onStart()}>
                    Start Module
                  </h4>
                </CardBody>
              </Card>}
              {status==='slide'&&<Card className="shadow">
                <CardBody>
                  {/* Chart */}
                  <div style={{height: 300}}>
                    <p className="text-center">
                      A not too short and not too long description of this Module can be inserted right here
                    </p>
                  </div>
                  <h4 className="text-center mb-0" style={{color: '#8b0000', cursor: 'pointer'}}
                    onClick={() => this.onFinish()}>
                    Done
                  </h4>
                </CardBody>
              </Card>}
              {status==='question'&&<Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col text-center">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        <span>Created 25th May, 2019</span>
                      </h6>
                      <h2 className="mb-0" style={{color: '#8b0000', cursor: 'pointer'}}>
                        Module Title4
                      </h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* Chart */}
                  <div style={{height: 300}}>
                    <p className="text-center">
                      A not too short and not too long description of this Module can be inserted right here
                    </p>
                  </div>
                  <h4 className="text-center mb-0" style={{color: '#8b0000', cursor: 'pointer'}}
                    onClick={() => this.onSubmit()}>
                    Submit
                  </h4>
                </CardBody>
              </Card>}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
