import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const axisService = {
    addAxis,
    getAxes,
    updateAxis,
    deleteAxis
};

export function getAxes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}axes`, requestOptions).then(authService.handleResponse);
}

export function addAxis(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addaxis`, requestOptions).then(authService.handleResponse);
}

export function updateAxis(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateaxis/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteAxis(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteaxis/${id}`, requestOptions).then(authService.handleResponse);
}
