/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  //NavItem,
  //NavLink,
  //Nav,
  //Progress,
  Table,
  Container,
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  ModalBody,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2
} from "variables/charts.jsx";
import "views/index.css";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    modules: [
      {title: 'Module Title4', date: '3rd August, 2019', status: 'New',
        score: '--', icon: 'far fa-star text-warning', id: 1},
      {title: 'Module Title3', date: '25th May, 2019', status: 'Not Completed',
        score: '40%', icon: 'fas fa-times text-danger', id: 2},
      {title: 'Module Title2', date: '8th February, 2019', status: 'Completed',
        score: '86%', icon: 'fas fa-check text-success', id: 3},
      {title: 'Module Title1', date: '17th November, 2018', status: 'Completed',
        score: '94%', icon: 'fas fa-check text-success', id: 4},
      {title: 'Module Title4', date: '3rd August, 2019', status: 'New',
        score: '--', icon: 'far fa-star text-warning', id: 5},
      {title: 'Module Title3', date: '25th May, 2019', status: 'Not Completed',
        score: '40%', icon: 'fas fa-times text-danger', id: 6},
      {title: 'Module Title2', date: '8th February, 2019', status: 'Completed',
        score: '86%', icon: 'fas fa-check text-success', id: 7},
      {title: 'Module Title1', date: '17th November, 2018', status: 'Completed',
        score: '94%', icon: 'fas fa-check text-success', id: 8}
    ],
    articles: [
      {title: 'Article Title9', date: '5th August, 2019',
        content: 'All incidents of sexual harassment – no matter how large or small or who is involved – require appropriately and timely response. Just because someone does not object to inappropriate behaviour at the time, does not mean that they are consenting to the behaviour.'},
      {title: 'Article Title8', date: '7th July, 2019',
        content: 'Men experience sexual harassment but it disproportionately affects women, especially in academic environments.'},
      {title: 'Article Title7', date: '2nd June, 2019',
        content: 'Sexual harassment is unwelcome sexual behaviour, which could be expected to make a person feel offended, humiliated or intimidated. It can be physical, verbal or written.'}
    ],
    modal: false,
    start: false,
    slide: null,
    module_id: null,
    module: null,
    question: null,
    slides: [
      {type: 'text', content: 'Some informative text', id: 1},
      {type: 'image', src: 'Some informative text', id: 2},
      {type: 'video', src: 'Some informative text', id: 3},
      {type: 'audio', src: 'Some informative text', id: 4},
      {type: 'text', content: 'Some informative text', id: 5}
    ],
    questions: [
      {content: '2 + 2?', type: 'single',
        id: 1,
        answers: [
          {value: '5', id: 1},
          {value: '4', id: 2},
          {value: '3', id: 3}
        ]
      },
      {content: '2 + 5?', type: 'multiple',
        id: 2,
        answers: [
          {value: '5', id: 1},
          {value: '5 + 2', id: 2},
          {value: '7', id: 3},
          {value: '3', id: 4}
        ]
      },
      {content: '10 + 5 = 105?', type: 'boolean',
        id: 3,
        answers: [
          {value: 'True', id: 1},
          {value: 'False', id: 2}
        ]
      }
    ],
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  componentDidMount() {
    const { state } = this.props.location;
    if(state&&state.module_id){
      this.getStore(state.module_id);
    } else {
      this.getStore(null);
    }
    this.props.history.replace({
      pathname: this.props.location.pathname,
      state: null
    });
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  onCancel(){
    this.setState({module: null, module_id: null, modal: false});
    localStorage.removeItem('module_id');
  }

  onStart(){
    this.setState({slide: 1});
    localStorage.setItem('module_id', this.state.module_id.toString());
    localStorage.setItem('slide', '1');
  }

  onFinish(){
    this.setState({question: 1, slide: null});
    localStorage.removeItem('slide');
    localStorage.setItem('question', '1');
  }

  onSubmit(){
    this.setState({module: null, module_id: null, modal: false, question:null});
    localStorage.removeItem('module_id');
    localStorage.removeItem('question');
  }

  getStore(state){
    let smodule_id = localStorage.getItem('module_id');
    let slide = localStorage.getItem('slide');
    let question = localStorage.getItem('question');
    if(slide===null&&question==null){
      this.setModule(state, null, state!==null);
      if(state!==null){
        localStorage.setItem('module_id', state.toString());
      }
    } else {
      this.setModule(smodule_id, null, true);
    }
    this.setState({
      slide,
      question
    });
  }

  setModule = (module_id, module, modal) => {
    this.setState({
      module_id,
      module: module_id!==null?this.state.modules.filter(m=>m.id===module_id)[0]:null,
      modal
    });
  }

  render() {
    const {module, slide, question} = this.state;
    return (
      <>
        <div className="header bg-gradient-success pb-8 pt-5 pt-md-6">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>

        {module!==null&&<Modal isOpen={this.state.modal} className="custom-map-modal"
          toggle={this.toggle} backdrop={'static'}>
          <ModalBody>
            <Row>
              <Col className="mb-5 mb-xl-0" xl="12">
                {slide===null&&question===null&&<Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col text-center">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          <span>Created {module.date}</span>
                        </h6>
                        <h2 className="mb-0" style={{color: '#8b0000', cursor: 'pointer'}}>
                          {module.title}
                        </h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div style={{height: 300}}>
                      <p className="text-center">
                        A not too short and not too long description of this Module can be inserted right here
                      </p>
                      <div style={{marginTop: 30, marginBottom: 30}}>
                        <p className="text-center" style={{marginBottom: 5}}>
                          You will be required to answer a few questions at the end of this module.
                        </p>
                        <p className="text-center">
                          This module will only be marked as COMPLETE if you answer at least 90% of the questions correctly.
                        </p>
                      </div>
                      <p className="text-center" style={{color: 'red', cursor: 'pointer'}}>
                        Do NOT close or refresh your browser after starting this course.
                      </p>
                    </div>
                    <h4 className="text-center mb-0"
                      style={{display: 'flex', justifyContent: 'space-evenly'}}>
                      <span className="text-muted" onClick={() => this.onCancel()} style={{cursor: 'pointer'}}>
                        Cancel
                      </span>
                      <span onClick={() => this.onStart()} style={{color: '#8b0000', cursor: 'pointer'}}>
                        Start Module
                      </span>
                    </h4>
                  </CardBody>
                </Card>}
                {slide!==null&&question===null&&<Card className="shadow">
                  <CardBody>
                    {/* Chart */}
                    <div style={{height: 300}}>
                      <p className="text-center">
                        A not too short and not too long description of this Module can be inserted right here
                      </p>
                    </div>
                    <h4 className="text-center mb-0" style={{color: '#8b0000', cursor: 'pointer'}}
                      onClick={() => this.onFinish()}>
                      Done
                    </h4>
                  </CardBody>
                </Card>}
                {slide===null&&question!==null&&<Card className="shadow">
                  <CardHeader className="bg-transparent">
                    <Row className="align-items-center">
                      <div className="col text-center">
                        <h6 className="text-uppercase text-muted ls-1 mb-1">
                          <span>Created 25th May, 2019</span>
                        </h6>
                        <h2 className="mb-0" style={{color: '#8b0000', cursor: 'pointer'}}>
                          Module Title4
                        </h2>
                      </div>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {/* Chart */}
                    <div style={{height: 300}}>
                      <p className="text-center">
                        A not too short and not too long description of this Module can be inserted right here
                      </p>
                    </div>
                    <h4 className="text-center mb-0" style={{color: '#8b0000', cursor: 'pointer'}}
                      onClick={() => this.onSubmit()}>
                      Submit
                    </h4>
                  </CardBody>
                </Card>}
              </Col>
            </Row>
          </ModalBody>
        </Modal>}
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Learning Modules </h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="success"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Refresh
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.modules.map((mod, key) => {
                      return (
                        <tr key={key} className="hover-list" href="/learn/modules/1"
                          onClick={() => {
                            this.setModule(mod.id, mod, true);
                            localStorage.setItem('module_id', mod.id.toString());
                          }}>
                          <th scope="row">{mod.title}</th>
                          <td>{mod.date}</td>
                          <td>{mod.status}</td>
                          <td>
                            <i className={`${mod.icon} mr-3`} />{" "}
                            {mod.score}
                          </td>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-center mb-0"
                      listClassName="justify-content-center mb-0"
                    >
                      <PaginationItem className="disabled">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                          tabIndex="-1"
                        >
                          <i className="fas fa-angle-left" />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className="active">
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          2 <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          3
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
