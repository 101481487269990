import React from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from 'react-select';
import ReactDatetime from "react-datetime";
import { getTarget, updateTarget }  from 'services/targetService';
import { filter, getUser }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";

const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class Target extends React.Component {
	constructor(props) {
		super(props);
    const {location: state} = props;
    this.state = {
      loading: false,
      edit: false,
      change: false,
      target: state&&state.target?state.target:null,
      validation: {},
      roles: [],
      superiors: [],
      states: [],
      territories: [],
      zones: [],
      axes: [],
      id: props.match.params.id,
      products: [],
      users: []
    };
	}

  componentDidMount() {
    this.getTarget();
  }

  getTarget = () => {
    const {id} = this.state
    this.setState({loading: true});
    getTarget(id)
      .then((res) => {console.log(res)
        this.setState({
          loading: false,
          target: {...res.target},
          initialTarget: {...res.target},
          roles: res.roles,
          states: res.states,
          superiors: res.superiors,
          territories: res.territories,
          zones: res.zones,
          axes: res.axes,
          users: res.users,
          products: res.products//.map(p=>({label:p.name, value:p.id})),
        })
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleEdit = () => {
    const {initialTarget} = this.state
    this.setState({edit: !this.state.edit, target: {...initialTarget}});
  }

  togglePassword = () => {
    this.setState({change: !this.state.change});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {target, validation} = this.state;
    const {quantity, superior_id, subordinate_id, product_id, state_id, territory_id, startdate, enddate, id} = target;
    await this.setState({
      validation: {
        ...validation,
        superior_id: superior_id!=='',
        subordinate_id: subordinate_id!=='',
        product_id: product_id!=='',
        quantity: quantity!=='',
        state_id: state_id!=='',
        territory_id: territory_id!=='',
        startdate: startdate!=='',
        enddate: enddate!==''
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveTarget();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  saveTarget = () => {
    this.setState({saving: true});
    const {target} = this.state;
    updateTarget({...target, startdate: moment(target.startdate).format('X'), enddate: moment(target.enddate).format('X')})
      .then(res => {
          console.log(res)
          this.setState({
            saving: false,
            edit: false,
            target: {...res.target},
            initialTarget: {...target},
            //target: res.target
          });
          this.showToast('Target Updated')
      },
    	error => {
    		console.log(error)
        this.setState({saving: false});
    	});
  }

  validationRules = field => {
    if(field==='superior_id'){
      return 'Superior is required';
    } else if(field==='subordinate_id'){
      return 'Subordinate is required';
    } else if(field==='product_id'){
      return 'Product is required';
    } else if(field==='quantity'){
      return 'Quantity is required';
    } else if(field==='state_id'){
      return 'State is required';
    } else if(field==='territory_id'){
      return 'Territory is required';
    } else if(field==='startdate'){
      return 'Start Date is required';
    } else if(field==='enddate'){
      return 'Send Date is required';
    }
  }

  onChange = (e, state) => {console.log(e)
    const {target} = this.state
    if(state==='state_id'){
      this.setState({
        target: {...target, territory_id: '', axis_id: '', zone_id: '', [state]: e}
      })
    } else if(state==='territory_id'){
      this.setState({target: {...target, axis_id: '', zone_id: '', [state]: e}})
    } else if(state==='axis_id'){
      this.setState({target: {...target, zone_id: '', [state]: e}})
    } else if(state==='status'){
      this.setState({target: {...target, status: e?'Active':'Inactive'}})
    } else {
      this.setState({target: {...target, [state]: e}})
    }
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state.target;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          console.log(res)
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes,
            zones: res.zones
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  dateFilter = async (e, state) => {
    const { target } = this.state
    //await this.onChange(e, state)
    if(state==='startdate'){
      await this.setState({target: {...target, startdate: e.clone(), enddate: e.endOf('month')}})
    }
    if(state==='enddate'){
      await this.setState({target: {...target, enddate: e.clone(), startdate: e.startOf('month')}})
    }
  }

  render() {
    const {target, loading, validation, edit, roles, superiors, states, territories, zones, axes,
      initialTarget, filtering, saving, change, products, users} = this.state
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    const customStyles = {
      control: (provided, state) => ({
        ...provided,
        minWidth: 170,
        height: 46,
        borderColor: 'hsl(0,0%,95%)',
        boxShadow: '1px 1px 1px rgba(50, 50, 50, 0.1)'
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
      }
    }

    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/targets"
                className="white-link"
                style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                <span>Back to Targets</span>
              </Link>
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {target&&<Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <Card className="card-profile shadow">
                {target&&<CardBody className="pt-0 pt-md-0">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span style={{display: 'unset'}} className="heading">{target.quantity}</span><span> bag{target.quantity===1?'':'s'}</span>
                          <span style={{display: 'block'}} className="description">{target.product_id}</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    {target.subordinate_name&&<div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Assigned to: <span style={{fontWeight: 'bold'}}>{target.subordinate_name}</span>
                    </div>}
                    {target.startdate!==null&&<div className="h5 mt-1 font-weight-300">
                      Start date: <span style={{fontWeight: 'bold'}}>{moment(target.startdate).format('MMM D, YYYY')}</span>
                    </div>}
                    {target.enddate!==null&&<div className="h5 mt-1 font-weight-300">
                      End date: <span style={{fontWeight: 'bold'}}>{moment(target.enddate).format('MMM D, YYYY')}</span>
                    </div>}
                    <hr className="my-4" />
                    <span style={{fontSize: 15}}>SUB-TARGETS</span>
                    {target.targets.map(t=>(<div key={t.id} className="h5 mt-1 font-weight-300 map-list">
                      <span style={{fontWeight: 'bold'}}>{t.subordinate_name}</span> - <span style={{fontWeight: 'bold'}}>{t.quantity}</span> bags
                    </div>))}
                  </div>
                </CardBody>}
              </Card>
            </Col>}
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                {saving&&<SpinDiv text={'Saving...'} />}
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      {initialTarget&&<h3 className="mb-0">Target for {initialTarget.subordinate_name}</h3>}
                    </Col>
                    {initialTarget&&(getUser().admin||getUser().id===initialTarget.superior_id)?
                    <Col className="text-right" xs="4">
                      {target&&<Button
                        color={edit?'secondary':"success"}
                        onClick={this.toggleEdit}
                        size="sm"
                      >
                        {edit?'Discard Changes':'Edit'}
                      </Button>}
                    </Col>:
                    <Col className="text-right" xs="4">
                      {target&&<Button
                        color="success"
                        onClick={this.addSubTarget}
                        size="sm"
                      >
                        Add Target
                      </Button>}
                    </Col>}
                  </Row>
                </CardHeader>
                {target&&<CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-2">
                      Target information
                    </h6>
                    <div>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-superior_id"
                              style={{color: validation.superior_id===false?'red':null}}
                            >
                              <Required />Superior
                            </label>
                            <Input value={target.superior_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled
                              onChange={async e => {
                                await this.onChange(e.target.value, 'superior_id');

                              }}>
                              <option disabled value=''>Select User</option>
                              {[target.superior].map((b)=><option value={b.id} key={b.id}>{b.name}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-subordinate_id"
                              style={{color: validation.subordinate_id===false?'red':null}}
                            >
                              <Required />Subordinate
                            </label>
                            <Input value={target.subordinate_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={!edit||target.targets.length>0}
                              onChange={async e => {
                                await this.onChange(e.target.value, 'subordinate_id');

                              }}>
                              <option disabled value=''>Select User</option>
                              {((getUser().id===initialTarget.subordinate_id)?[initialTarget.subordinate]:users).map((m)=><option value={m.id} key={m.id}>{m.name}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-product_id"
                              style={{color: validation.product_id===false?'red':null}}
                            >
                              <Required />Product
                            </label>
                            <Input value={target.product_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={!edit||target.targets.length>0}
                              onChange={async e => {
                                await this.onChange(e.target.value, 'product_id');
                               }}>
                              <option disabled value=''>Select Product</option>
                              {products.map((p)=><option value={p} key={p}>{p}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-quantity"
                              style={{color: validation.quantity===false?'red':null}}
                            >
                              <Required />Quantity
                            </label>
                            <Input
                              style={{height: 31}}
                              disabled={!edit}
                              className="form-control-alternative"
                              value={target.quantity}
                              id="input-quantity"
                              onChange={e=>this.onChange(e.target.value, 'quantity')}
                              placeholder="quantity"
                              type="number"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              <Required />State
                            </label>
                            <Input value={target.state_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit||target.targets.length>0}
                              onChange={async e => {
                                await this.onChange(e.target.value, 'state_id');
                                await this.filter();
                              }}>
                              <option disabled value=''>Select State</option>
                              {states.map((p)=><option value={p} key={p}>{p}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-territory"
                            >
                              <Required />Territory
                            </label>
                            <Input value={target.territory_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                              disabled={filtering||!edit||target.targets.length>0}
                              onChange={async e => {
                                await this.onChange(e.target.value, 'territory_id');
                                await this.filter();
                              }}>
                              <option disabled value=''>Select Territory</option>
                              {territories.map((p)=><option value={p} key={p}>{p}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="form-date">
                            <label
                              className="form-control-label"
                              htmlFor="input-start-date"
                              style={{color: validation.startdate===false?'red':null}}
                            >
                              <Required />Start Date
                            </label>
                            <ReactDatetime
                                value={moment(target.startdate)}
                                dateFormat={'MMM D, YYYY'}
                                closeOnSelect
                                onChange={e=>this.dateFilter(e, 'startdate')}
                                inputProps={{
                                  required: true,
                                  className: 'form-control date-width',
                                  disabled: !edit
                                }}
                                //isValidDate={(current)=>{return (current.isBefore(target.enddate)||current.isSame(target.enddate));}}
                                timeFormat={false}
                              />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="form-date">
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                              style={{color: validation.enddate===false?'red':null}}
                            >
                              <Required />End Date
                            </label>
                            <ReactDatetime
                                value={moment(target.enddate)}
                                disabled={filtering||!edit}
                                dateFormat={'MMM D, YYYY'}
                                closeOnSelect
                                onChange={e=>this.dateFilter(e, 'enddate')}
                                inputProps={{
                                  required: true,
                                  className: 'form-control date-width',
                                  disabled: !edit
                                }}
                                style={{width: '100%'}}
                                //isValidDate={(current)=>{return (current.isAfter(target.startdate)||current.isSame(target.startdate));}}
                                timeFormat={false}
                              />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr style={{marginBottom: 20, marginTop: 10}} />
                    {edit&&<div>
                      <Button color="success" type="button" disabled={saving}
                        onClick={this.onSave}
                        style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
                        Save
                      </Button>
                    </div>}
                  </Form>
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Target;
