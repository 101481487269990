import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input
} from "reactstrap";
import { toast } from 'react-toastify';

import { updateProduct }  from 'services/productService';
import SpinDiv from "components/SpinDiv.jsx";

class EditProduct extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      product: props.editProduct,
      loading: false,
      search: '',
      validation: {},
      name: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {product, validation} = this.state;
    const {name} = product;
    await this.setState({
      validation: {
        ...validation,
        name: name!=='',
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveProduct();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if(field==='name'){
      return 'Name is required';
    }
  }

  saveProduct = () => {
    this.setState({loading: true});
    const {name, id} = this.state.product;
    updateProduct({name, id})
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('Product Updated')
      },
    	error => {
    		console.log(error)
        if(error.name){
          this.showToast('A product with this name already exists')
        }
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    const {product} = this.state
    this.setState({product: {...product, [state]: e}})
  }



  render() {
    const { editProduct, toggle } = this.props
    const {loading, validation, product} = this.state;
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={editProduct!=null}
          toggle={() => !loading&&toggle}
          style={{maxWidth: 600}}
        >
          {loading&&<SpinDiv text={'Saving...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Edit Product
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-name"
                    style={{color: validation.name===false?'red':null}}
                  >
                    <Required />Name
                  </label>
                  <Input
                    className="form-control-alternative"
                    value={product.name||''}
                    id="input-name"
                    onChange={e=>this.onChange(e.target.value, 'name')}
                    placeholder="Name"
                    type="text"
                  />
                </FormGroup>
              </Col>
            </Row>
           </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="success" type="button" disabled={loading} size="sm"
              onClick={this.onSave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default EditProduct;
