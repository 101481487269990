/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// node.js library that concatenates classes (strings)
//import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
//import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  //Button,
  Card,
  CardHeader,
  CardBody,
  //NavItem,
  //NavLink,
  //Nav,
  //Progress,
  //Table,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  //chartExample1,
  //chartExample2
} from "variables/charts.jsx";

class Index extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
    modules: [
      {title: 'Module Title4', date: '3rd August, 2019', status: 'New',
        score: '--', icon: 'far fa-star text-warning'},
      {title: 'Module Title3', date: '25th May, 2019', status: 'Not Completed',
        score: '40%', icon: 'fas fa-times text-danger'},
      {title: 'Module Title2', date: '8th February, 2019', status: 'Completed',
        score: '86%', icon: 'fas fa-check text-success'},
      {title: 'Module Title1', date: '17th November, 2018', status: 'Completed',
        score: '94%', icon: 'fas fa-check text-success'}
    ],
    articles: [
      {title: 'Article Title9', date: '5th August, 2019',
        content: 'All incidents of sexual harassment – no matter how large or small or who is involved – require appropriately and timely response. Just because someone does not object to inappropriate behaviour at the time, does not mean that they are consenting to the behaviour.'},
      {title: 'Article Title8', date: '7th July, 2019',
        content: 'Men experience sexual harassment but it disproportionately affects women, especially in academic environments.'},
      {title: 'Article Title7', date: '2nd June, 2019',
        content: 'Sexual harassment is unwelcome sexual behaviour, which could be expected to make a person feel offended, humiliated or intimidated. It can be physical, verbal or written.'},
      {title: 'Article Title9', date: '5th August, 2019',
        content: 'All incidents of sexual harassment – no matter how large or small or who is involved – require appropriately and timely response. Just because someone does not object to inappropriate behaviour at the time, does not mean that they are consenting to the behaviour.'},
      {title: 'Article Title8', date: '7th July, 2019',
        content: 'Men experience sexual harassment but it disproportionately affects women, especially in academic environments.'},
      {title: 'Article Title7', date: '2nd June, 2019',
        content: 'Sexual harassment is unwelcome sexual behaviour, which could be expected to make a person feel offended, humiliated or intimidated. It can be physical, verbal or written.'}
    ]
  };
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1"
    });
    let wow = () => {
      console.log(this.state);
    };
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };
  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  render() {
    return (
      <>
        <div className="header bg-gradient-success pb-8 pt-5 pt-md-6">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            {this.state.articles.map((article, key) => {
              return (
                  <Col className="mb-5" key={key} md="6" xl="4">
                    <Card className="shadow">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">
                            <h6 className="text-uppercase text-muted ls-1 mb-1">
                              <span>{article.date}</span>
                            </h6>
                            <h2 className="mb-0" style={{color: '#8b0000', cursor: 'pointer'}}>
                              {article.title}
                            </h2>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {/* Chart */}
                        <div style={{height: 200}}>
                          <p>
                            {article.content.substring(0,200)+(article.content.length>250?'...':'')}
                          </p>
                        </div>
                        <h4 className="mb-0" style={{color: '#8b0000', cursor: 'pointer'}}>Read more</h4>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </>
    );
  }
}

export default Index;
