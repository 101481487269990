import React from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Badge,
  //UncontrolledTooltip,
  Media,
  Table,
  Container,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import moment from "moment";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { isMobile } from "react-device-detect";

import "views/index.css";
import { getUsers }  from 'services/messageService';
import { getUser, openSocket, messageSocket }  from 'services/authService';
import {toPlay} from 'services/soundService'
import SpinDiv from "components/SpinDiv.jsx";
import Chat from "./Chat";
import Broadcast from "./Broadcast";
import Avatar from './Chat/Avatar'
import CustomPagination from "components/CustomPagination.jsx";
import AddUser from "./AddUser.jsx";

class Messages extends React.Component {
  state = {
    activeNav: 1,
    loading: true,
  	page: 1,
  	rows: 25,
  	total: 0,
    roles: [],
    states: [],
    search: '',
    territories: [],
    axes: [],
    zones: [],
    role: 'All Roles',
    state: 'All States',
    territory: 'All Territories',
    axis: 'All Axes',
    zone: 'All Zones',
    superiors: [],
    users: []
  };

  componentDidMount() {
    this.getUsers();
  }

  componentWillMount() {
  }

  async componentWillUnmount() {
    let url = `chats.${getUser().id}`;
    window.Echo.leave(url)
    messageSocket(this.props.history)
  }

  listen = () => {
    let url = `chats.${getUser().id}`;
    if(!window.Echo){
      openSocket();
    }
    window.Echo.private(url)
      .listen('NewMessage', e => {
        const {users, user} = this.state;
        if(!user||(user&&user.id!==e.message.sender_id)){
          let theuser
          const theusers = users.filter(u=>u.id===e.message.sender_id);
          if(theusers.length>0) theuser = theusers[0];
          const newusers = users.filter(u=>u.id!==e.message.sender_id);
          if(theuser){
            this.setState({users: [{...theuser, lastm: moment(), unread: theuser.unread+1}].concat(newusers)});
          }
          toPlay('/receive.mp3');
        }
      })
  }

  getUsers = () => {
    const {role, state, territory, axis, zone, page, rows, search} = this.state;
    this.setState({loading: true});
    getUsers({role, state, territory, axis, zone, page, rows, search})
      .then((res) => {console.log(res)
        this.setState({
          users: res.users.data,
          roles: res.roles,
          states: res.states,
          territories: res.territories,
          axes: res.axes,
          zones: res.zones,
          page: res.users.current_page,
          total: res.users.total,
          loading: false
        })
        this.listen();
      },
      error => {
        this.setState({loading: false});
      });
  }

  toggleModal = () => {
    toast.dismiss()
    this.setState({addUser: !this.state.addUser});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  onSearch = () => {

  }

  onFilter = async (e, filter) => {
    if(filter==='state'){
      await this.setState({territory: 'All Territories', axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='territory'){
      await this.setState({axis: 'All Axes', zone: 'All Zones'});
    } else if(filter==='axis'){
      await this.setState({zone: 'All Zones'});
    }
    await this.setState({[filter]: e});
    await this.getUsers();
  }

  onPage = async (page) => {
    await this.setState({page});
    await this.getUsers();
  }

  onUser = async (user) => {
    await this.setState({user:null});
    if(user==='broadcast'){
      await this.setState({broadcast: true});
    } else {
      await this.setState({user, broadcast: false});
    }
  }

  update = () => {
    const {users, user} = this.state;
    const newusers = users.filter(u=>u.id!==user.id);
    this.setState({users: [{...user, lastm: moment()}].concat(newusers)})
  }

  open = () => {
    const {users, user} = this.state;
    const newusers = users.filter(u=>u.id!==user.id);
    const newuser = {...user, lastm: moment(), unread: 0};
    this.setState({user: newuser, users: [newuser].concat(newusers)});
  }

  render() {
    const {users, showFilter, role, roles, states, state, territories, territory, addUser,
      axes, axis, zones, zone, total, page, rows, search, loading, superiors, user, broadcast} = this.state;
    return (
      <>
        <div className={`header pb-8 ${isMobile?'':'pt-5 pt-md-6'}`} style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
            </div>
          </Container>
        </div>
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={search===''?'Loading...':'Searching...'} />}
                <CardHeader className="border-0">
                  <div style={{display: 'flex'}}>
                    {(!isMobile||(!user&&!broadcast))&&<div style={{borderRight: (user||isMobile||broadcast)?0:'1px #eee solid', paddingRight: 20}}>
                      <Row className="align-items-center" style={{justifyContent:'space-between', padding: '0 15px', overflowX: 'scroll', overflow: 'visible', flexWrap: 'nowrap', marginBottom: 10}}>
                        <div className="mobile-flex">
                          <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5, minWidth: 80}}
                            value={search}
                            onChange={e=>this.onChange(e.target.value, 'search')}
                            onKeyUp={(e) => {if(e.key==='Enter'){this.getUsers();}}} />
                          <Button className="btn-icon btn-2 hide-mobile" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getTracking}>
                            <i className="fa fa-search" />
                          </Button>
                        </div>
                      </Row>
                      {<div style={{backgroundColor: broadcast?'#efefef':'#EC3237'}} className={`broadcast ${broadcast?'':'broadcast-on'}`} onClick={()=>this.onUser('broadcast')}>
                        <p style={{fontSize: 12, fontWeight: '600', margin: 0, whiteSpace: 'nowrap'}}>Broadcast</p>
                        <p style={{margin: 0, fontSize: 11, lineHeight: 1, fontWeight: '400', whiteSpace: 'nowrap'}}>Send Message to multiple Users</p>
                      </div>}
                      <div style={{overflowY: 'auto', height: total>25?384:420, marginLeft: -10, paddingRight: 10}}>
                        {users.map((u)=>(
                          <div key={u.id} className="map-list" style={{display: 'flex', marginLeft: 5, alignItems: 'center', position: 'relative'}} onClick={()=>this.onUser(u)}>
                            <Avatar src={`${u.picture_url||require("assets/img/brand/user.jpg")}`} size="50" border="false" />
                            <div style={{color: user&&user.id===u.id?'#EC3237':null, marginLeft: 7}}>
                              <p style={{fontSize: 12, fontWeight: '600', margin: 0, whiteSpace: 'nowrap'}}>{u.name}{u.role_id?` (${u.role_id})`:''}</p>
                              <p style={{margin: 0, fontSize: 11, lineHeight: 1, color:u.lastm?(u.unread>0?'#EC3237':null):'#ccc'}}>{u.lastm?moment(u.lastm).calendar(null, {sameDay: 'HH:mm', lastDay: '[Yesterday]', lastWeek: 'dddd', sameElse: 'MMM D, YYYY'}):'N/A'}</p>
                            </div>
                            {u.unread>0&&<Badge color="danger" style={{marginLeft: 10, position: 'absolute', right: 12}}>{u.unread}</Badge>}
                          </div>
                        ))}
                      </div>
                      {!loading&&users.length<1&&
                      <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                        <i className="fa fa-ban" style={{marginRight: 5}}/>
                        No user found
                      </div>}
                      {total>25&&<div style={{marginTop: 20}}>
                        <CustomPagination page={page} total={total} rows={rows} onPage={this.onPage} />
                      </div>}
                    </div>}
                    {user&&<Chat sender={getUser().id} receiver={user} height={408} close={()=>this.setState({user:null})} open={this.open} update={this.update} />}
                    {broadcast&&<Broadcast sender={getUser().id} height={408} close={()=>this.setState({broadcast:false})} />}
                  </div>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Messages;
