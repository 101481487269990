import Index from "views/learn/Index.jsx";
import Profile from "views/learn/Profile.jsx";
//import Tables from "views/learn/Tables.jsx";
//import Icons from "views/learn/Icons.jsx";
import Modules from "views/learn/Modules.jsx";
import Articles from "views/learn/Articles.jsx";
//import Module from "views/learn/Module.jsx";

var lroutes = [
  {
    path: "/index",
    name: "Home",
    icon: "fas fa-home text-info",
    component: Index,
    layout: "/learn"
  },
  {
    path: "/modules",
    name: "Learning Modules",
    icon: "ni ni-hat-3 text-green",
    component: Modules,
    layout: "/learn"
  },
  {
    path: "/articles",
    name: "Articles",
    icon: "ni ni-single-copy-04 text-blue",
    component: Articles,
    layout: "/learn"
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/learn"
  }
];
export default lroutes;
