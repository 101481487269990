import React from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { toast } from 'react-toastify';

import { addTarget, getTargets }  from 'services/targetService';
import { filter }  from 'services/authService';
import SpinDiv from "components/SpinDiv.jsx";
import ReactDatetime from "react-datetime";
import moment from "moment";


//const emailReg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneReg=/^([0]\d{10})$/;

class AddTarget extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      activeNav: 1,
      loading: false,
      search: '',
      states: props.states,
      products: props.products,
      product: 'All Products',
      territories:  props.territories,
      axes: [],
      zones: [],
      users: props.users,
      validation: {},
      status: '',
      active: '',
      state_id: '',
      territory_id: '',
      user_id: '',
      product_id: '',
      superior: props.superior,
      superior_id: props.superior.id,
      subordinate_id: '',
      quantity: '',
      startdate: moment().startOf('month'),
      enddate: moment().endOf('month')
    };

	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {quantity, superior_id, subordinate_id, product_id, state_id, territory_id, startdate, enddate, validation} = this.state;
    await this.setState({
      validation: {
        ...validation,
        superior_id: superior_id!=='',
        subordinate_id: subordinate_id!=='',
        product_id: product_id!=='',
        quantity: quantity!=='',
        state_id: state_id!=='',
        territory_id: territory_id!=='',
        startdate: startdate!=='',
        enddate: enddate!==''
      }
    })
    if(Object.values(this.state.validation).every(Boolean)){
      this.saveTarget();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      //await toast.configure({hideProgressBar: true, closeButton: false});
      await setTimeout(()=>toast.error(
        <div style={{padding:'10px 20px'}}>
          <p style={{margin: 0, fontWeight: 'bold'}}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{margin: 0, fontSize: 14}}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if(field==='superior_id'){
      return 'Superior is required';
    } else if(field==='subordinate_id'){
      return 'Subordinate is required';
    } else if(field==='product_id'){
      return 'Product is required';
    } else if(field==='quantity'){
      return 'Quantity is required';
    } else if(field==='state_id'){
      return 'State is required';
    } else if(field==='territory_id'){
      return 'Territory is required';
    } else if(field==='startdate'){
      return 'Start Date is required';
    } else if(field==='enddate'){
      return 'Send Date is required';
    }
  }

  saveTarget = () => {
    this.setState({loading: true});
    const {superior_id, subordinate_id, user_id, product_id, state_id, territory_id, quantity, startdate, enddate, active} = this.state;
    addTarget({superior_id, subordinate_id, user_id, product_id, state_id, territory_id, quantity, startdate: moment(startdate).format('X'), enddate: moment(enddate).format('X'), active} )
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('Target Created')
      },
    	error => {
    		console.log(error)
        if(error.phone){
          this.showToast('A similar target already exixts')
        }
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    if(state==='superior_id'){
      this.setState({subordinate_id: '', product_id: ''})
    }
    if(state==='subordinate_id'){
      this.setState({product_id: ''})
    }

    this.setState({[state]: e})
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id, territory_id, axis_id} = this.state;
    filter({state_id, territory_id, axis_id})
      .then(res => {
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes,
            zones: res.zones
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  dateFilter = async (e, state) => {
    await this.setState({[state]: e})
    if(state==='startdate'){
      await this.setState({enddate: e.endOf('month')})
    }
    if(state==='enddate'){
      await this.setState({startdate: e.startOf('month')})
    }
  }

  render() {
    const { addTarget, toggle} = this.props
    const {targets, saving, loading, active, validation, show, user_id, superior_id, subordinate_id, filtering, users,
       product_id, product, products, startdate, enddate, states, territories, state_id, territory_id, superior} = this.state;
    const Required = () => (<span style={{color: 'red'}}>*</span>)
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={addTarget}
          toggle={() => !saving&&toggle}
          style={{maxWidth: 700}}
        >
          {loading&&<SpinDiv text={'Saving...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Add Target
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>x</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            <Row>
              <Col lg="6">
              <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-last-name"
                    style={{color: validation.superior_id===false?'red':null}}
                  >
                    <Required />Superior
                  </label>
                  <Input value={superior_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled
                    onChange={async e => {
                      await this.onChange(e.target.value, 'superior_id');

                    }}>
                    <option disabled value=''>Select User</option>
                    {[superior].map((b)=><option value={b.id} key={b.id}>{b.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
              <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-subordinate"
                    style={{color: validation.subordinate_id===false?'red':null}}
                  >
                  <Required />Subordinate
                  </label>
                  <Input value={subordinate_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    //disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'subordinate_id');

                    }}>
                    <option disabled value=''>Select User</option>
                    {users.map((m)=><option value={m.id} key={m.id}>{m.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
            <Col lg="6">
              <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                    style={{color: validation.product_id===false?'red':null}}
                  >
                    <Required />Product
                  </label>
                  <Input value={product_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    //disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'product_id');
                     }}>
                    <option disabled value=''>Select Product</option>
                    {products.map((p)=><option value={p} key={p}>{p}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-quantity"
                    style={{color: validation.quantity===false?'red':null}}
                  >
                    <Required />Quantity
                  </label>
                  <Input
                    style={{height: 31}}
                    className="form-control-alternative"
                    defaultValue=""
                    id="input-quantity"
                    onChange={e=>this.onChange(e.target.value, 'quantity')}
                    placeholder="quantity"
                    type="number"
                  />
                </FormGroup>
              </Col>
             </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                    style={{color: validation.state_id===false?'red':null}}
                  >
                    <Required />State
                  </label>
                  <Input value={state_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}
                    onChange={async e => {
                      await this.onChange(e.target.value, 'state_id');
                      await this.filter();
                    }}>
                    <option disabled value=''>Select State</option>
                    {states.map((p)=><option value={p} key={p}>{p}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-product_id"
                    style={{color: validation.territory_id===false?'red':null}}
                  >
                    <Required />Territory
                  </label>
                  <Input value={territory_id} type="select" bsSize="sm" style={{marginRight: 10, width: '100%'}}
                    disabled={filtering}

                    onChange={async e => {
                      await this.onChange(e.target.value, 'territory_id');
                     }}>
                    <option disabled value=''>{filtering?'loading...':'Select Territory'}</option>
                    {territories.map((p)=><option value={p} key={p}>{p}</option>)}
                  </Input>
                </FormGroup>
              </Col>
             </Row>
             <Row>
              <Col lg="6">
                <FormGroup className="form-date">
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                    style={{color: validation.startdate===false?'red':null}}
                  >
                    <Required />Start Date
                  </label>
                  <ReactDatetime
                      value={startdate}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e=>this.dateFilter(e, 'startdate')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      //isValidDate={(current)=>{return (current.isBefore(enddate)||current.isSame(enddate));}}
                      timeFormat={false}
                    />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup className="form-date">
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                    style={{color: validation.enddate===false?'red':null}}
                  >
                    <Required />End Date
                  </label>
                  <ReactDatetime
                      value={enddate}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e=>this.dateFilter(e, 'enddate')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{width: '100%'}}
                      //isValidDate={(current)=>{return (current.isAfter(startdate)||current.isSame(startdate));}}
                      timeFormat={false}
                    />
                </FormGroup>
              </Col>
             </Row>

            </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={toggle}
            >
              Close
            </Button>
            <Button color="success" type="button" disabled={saving} size="sm"
              onClick={this.onSave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddTarget;
