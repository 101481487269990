import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";
import { getTerritories }  from 'services/territoryService';
import AddTerritory from "./AddTerritory";
import EditTerritory from "./EditTerritory";
import DeleteTerritory from "./DeleteTerritory";
import SpinDiv from "components/SpinDiv.jsx";
import "views/index.css";
import { filter }  from 'services/authService';


class Territories extends React.Component {
  state = {
    states: [],
  	page: 1,
  	total: 0,
    search: '',
    territories: [],
    state: 'All States',
    territory: 'All Territories',
    axis: 'All Axes',
  };

  componentDidMount() {
    this.getTerritories();
  }

  getTerritories = () => {
    const {state, page, search} = this.state;
    this.setState({loading: true});
    getTerritories({state, page, search})
      .then((res) => {
        this.setState({
          territories: res.territories.data,
          states: res.states,
          page: res.territories.current_page,
          total: res.territories.total,
          loading: false
        })
      },
      error => {console.log(error)
        this.setState({loading: false});
      });
  }

  toggleAdd = () => {
    this.setState({add: !this.state.add});
  }

  toggleEdit = (editTerritory) => {
    this.setState({editTerritory});
  }

  toggleDelete = (deleteTerritory) => {
    this.setState({deleteTerritory});
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onChange = (e, state) => {
    this.setState({[state]: e})
  }

  filter = () => {
    this.setState({filtering: true});
    const {state_id} = this.state;
    filter({state_id})
      .then(res => {
          console.log(res)
          this.setState({
            filtering: false,
            territories: res.territories,
            axes: res.axes
          });
      },
    	error => {
    		console.log(error)
        this.setState({filtering: false});
    	});
  }

  onFilter = async (e, state) => {
    await this.setState({[state]: e});
    await this.getTerritories();
  }

  render() {
    const {add, states, loading, showFilter, search, state, territory, territories,
       filtering, editTerritory, deleteTerritory} = this.state;
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-6" style={{backgroundColor: '#F85C46'}}>
          <Container fluid>
            <div className="header-body">
              <Link
                to="/admin/settings"
                className="white-link"
                style={{position: 'absolute', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                <i className=" ni ni-bold-left" style={{marginRight: 5}} />
                <span>Back to Settings</span>
              </Link>
            </div>
          </Container>
        </div>
        {/* Page content */}
        {add&&
          <AddTerritory
            saved={this.getTerritories}
            toggle={this.toggleAdd}
            states={states}
            add={add}
          />}
        {editTerritory&&
          <EditTerritory
            saved={this.getTerritories}
            states={states}
            editTerritory={editTerritory}
            toggle={()=>this.setState({editTerritory: null})}
          />}
        {deleteTerritory&&
          <DeleteTerritory
            saved={this.getTerritories}
            states={states}
            deleteTerritory={deleteTerritory}
            toggle={()=>this.setState({deleteTerritory: null})}
          />}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                {loading&&<SpinDiv text={'Loading...'} />}
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Territories </h3>
                    </div>
                    <div style={{display: 'flex'}}>
                      {!showFilter&&<Button
                        color="warning"
                        onClick={this.toggleFilter}
                        size="sm"
                        style={{marginRight: 10}}
                      >
                        Filter
                      </Button>}
                      <Input placeholder="Search..." style={{maxHeight: 28, marginRight: 5}}
                        value={search}
                        onChange={e=>this.onChange(e.target.value, 'search')}
                        onKeyUp={(e) => {if(e.key==='Enter'){this.getTerritories();}}} />
                      <Button className="btn-icon btn-2" color="secondary" size="sm" style={{marginRight: 10}} onClick={this.getTerritories}>
                        <i className="fa fa-search" />
                      </Button>
                      <Button
                        color="success"
                        onClick={this.toggleAdd}
                        size="sm"
                      >
                        Add Territory
                      </Button>
                    </div>
                  </Row>
                </CardHeader>
                {showFilter&&<div style={{height: 50, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Filter Territories: </span>
                    <Input value={state} type="select" bsSize="sm" style={{marginRight: 10, width: 'fit-content'}}
                      onChange={e=>this.onFilter(e.target.value, 'state')}>
                      <option>All States</option>
                      {states.map((s)=><option value={s} key={s}>{s}</option>)}
                    </Input>
                  </div>
                  <Button
                    color="warning"
                    onClick={this.toggleFilter}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Hide Filters
                  </Button>
                </div>}
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">State</th>
                      <th scope="col">No. of Customers</th>
                      <th scope="col">No. of Users</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {territories.map((territory) => {
                      return (
                        <tr key={territory.id} className="hover-list">
                          <th scope="row">{territory.name}</th>
                          <td>{territory.state_id}</td>
                          <td>{territory.customers_count}</td>
                          <td>{territory.users_count}</td>
                          <td>
                            <Button
                              color="secondary"
                              onClick={() => this.toggleEdit(territory)}
                              size="sm"
                            >
                              Edit
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => this.toggleDelete(territory)}
                              size="sm"
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                        );
                      })}
                  </tbody>
                </Table>
                {!loading&&territories.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Territory found
                </div>}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Territories;
