import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export const roleService = {
    addRole,
    getRoles,
    updateRole,
    deleteRole
};

export function getRoles(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}roles`, requestOptions).then(authService.handleResponse);
}

export function addRole(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addrole`, requestOptions).then(authService.handleResponse);
}

export function updateRole(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updaterole/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteRole(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleterole/${id}`, requestOptions).then(authService.handleResponse);
}
