import React from "react";
import Echo from "laravel-echo"
import { toast } from 'react-toastify';
import {toPlay} from 'services/soundService'
import settings from './settings';
import { authHeader } from './authHeader';

export const authService = {
    login,
    logout,
    openSocket,
    profile,
    userPassword,
    filter,
    handleResponse
};

export function openSocket() {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'ABCDE',
    // cluster: 'mt1',
    //encrypted: true,
    enabledTransports: ['ws','wss'],
    disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming'],
    wsHost: 'api.repton.com.ng',
    wssPort: 'api.repton.com.ng',
    wsPort: 6555,
    wssPort: 6555,
    authEndpoint: settings.WS_URL,
    auth: {
      headers: {
        Authorization: `Bearer ${getUser().token}`,
        accept: 'application/json',
      },
    },
  });
}

export function messageSocket(history) {
  window.Echo.private(`chats.${getUser().id}`)
    .listen('NewMessage', e => {
      if(history.location.pathname!=="/admin/messages"){
        toPlay('/receive.mp3');
        toast.error(
          <div style={{padding:'10px 20px'}}>
            <p style={{margin: 0, fontSize: 15}}>New message from <span style={{fontWeight: 'bold'}}>{e.message.sender_name}</span></p>
            <p style={{margin: 0, fontSize: 14}}>{e.message.content}</p>
            <p style={{margin: 0, textAlign: 'right'}}>
              <span style={{fontSize: 12, textDecoration: 'underline'}} onClick={()=>history.push('/admin/messages')}>Reply</span>
            </p>
          </div>, {
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 10000
        });
      }
    })
}

export function getUser() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user||false;
}

export function filter(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}filter`, requestOptions).then(authService.handleResponse);
}

export function login({username, password}) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };
    return fetch(`${settings.API_URL}login`, requestOptions)
        .then(handleResponse)
        .then(response => {
            if (response.user&&!response.user.change_password) {
                response.user.token = response.token;
                localStorage.setItem('user', JSON.stringify(response.user));
            }
            return response;
        });
}

export function profile() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}getuser`, requestOptions).then(authService.handleResponse);
}

export function userPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${settings.API_URL}password`, requestOptions).then(authService.handleResponse);
}

export function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
}

export function handleResponse(response) {
    return response.text().then(text => {
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //window.location.reload(true);
            } else if (response.status === 403) {
                // auto logout if 401 response returned from api
                window.location.href = '/admin/home';
                //window.location.reload(true);
            } else if (response.status === 418) {
                // auto logout if 401 response returned from api
                //window.location.reload(true);
            }

            const error = data || response.statusText;
            return Promise.reject(error);
        }
        const data = text && JSON.parse(text);
        return data;
    });
}
